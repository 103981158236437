.cart-empty-wc {
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 70px;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
  .error,
  .cta {
    width: 50%;
    height: 380px;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      height: 240px;
    }
  }
  .error {
    background-color: $blue;
    p {
      color: $white;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1px;
      font-weight: $bold;
      &.p-inline {
        position: relative;
        width: fit-content;
        margin-bottom: 40px;
        text-transform: uppercase;
        @media (max-width: $mobile-breakpoint) {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 25px;
        }
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -10px;
          border-width: 0 0 2px;
          border-style: solid;
          border-color: $white;
        }
      }
    }
  }
  .cta {
    background-color: $light-blue;
    @media (max-width: $mobile-breakpoint) {
      margin-top: -1px;
    }
  }
  .card-icons {
    max-width: 50px;
  }
  br {
    display: none;
  }
  .a-primary {
    &:first-child {
      display: block;
      margin-bottom: 10px;
    }
    /*
    &:last-child {
      position: absolute;
      right: 7rem;
    }
    */
  }
  .cart-empty-minh {
    padding-top: 5px;
    padding-bottom: 20px;
    p {
      &:nth-child(2) {
        min-height: 55px;
      }
    }
  }
  @media (max-width: $mobile-breakpoint) {
    .display-block-mobile {
      display: block!important;
      padding: 7px;
      text-align: center;
    }
  }
}