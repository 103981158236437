.m05 {
  padding: 30px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 30px 0;
  }

  .box-m5 {

    text-align: center;
    min-height: 500px;
    @media (max-width: $grid-float-breakpoint-max) {
      min-height: 400px;
      margin-bottom: 20px;
    }


    &.blue {
      background: $blue;
    }



    .brand-logo {
      max-width: 150px;
      height: auto;

      margin-top: 113px;
      margin-bottom: 40px;
      @media (max-width: $grid-float-breakpoint-max) {
        max-width: 130px;
        margin-top: 53px;
        margin-bottom: 26px;
      }
    }

    .brand-description {
      margin: 0 auto 88px auto;
      color: $white;
      font-weight: 400;
      max-width: 260px;

      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 auto 57px auto;

      }
    }


    &.video {
      @media (min-width: $grid-float-breakpoint) {
        background: $black;
        width: 100%;
        height: 500px;
        overflow: hidden;
      }
      @media (max-width: $mobile-breakpoint) {
          min-height: initial;
          margin: 0;
      }

      video {
        width: 100%;
        @media (min-width: $grid-float-breakpoint) {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

      }
    }
  }


}
