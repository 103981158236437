.cc-window {
  background-color: $light-blue!important;
  color: $white!important;
  font-size: 16px!important;
  line-height: 35px!important;
  @media (min-width: $mobile-breakpoint + 1) {
    padding: 22px 130px!important;
  }
  @media (max-width: $mobile-breakpoint) {
    br {
      display: none;
    }
  }
  a {
    color: $white!important;
    font-size: 16px!important;
    line-height: 24px!important;
    &.cc-btn {
      margin: 0!important;
      position: relative!important;
      font-size: 16px!important;
      letter-spacing: 1px!important;
      text-transform: uppercase!important;
      height: 60px!important;
      font-family: "Roboto Condensed", sans-serif!important;
      border-radius: 0!important;
      padding: 18px 36px!important;
      display: inline-block!important;
      cursor: pointer!important;
      -webkit-transition: ease-out 0.4s!important;
      -o-transition: ease-out 0.4s!important;
      transition: ease-out 0.4s!important;
      color: #182A73!important;
      background-color: #ffffff!important;
      font-weight: 700!important;
      border: 2px solid transparent!important;
      -webkit-box-shadow: inset 0 0 0 0 #F3F3F3!important;
      box-shadow: inset 0 0 0 0 #F3F3F3!important;
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small.svg) no-repeat;
        height: 8px;
        width: 5px;
        margin-left: 10px;
        margin-top: 6px;
      }
    }
  }
}