.m08 {
  width: 100%;
  padding-top: 57px;
  @media (min-width: $grid-float-breakpoint) {
    background-color: $blue;
    background: linear-gradient(90deg, $blue 57%, $light-blue 0);
    height: 240px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 0px
  }

  .sub-header {
    color: $white;
    font-size: 12px;
    border-bottom: 2px solid $white;
    display: inline-block;
    padding-right: 2px;
    padding-bottom: 7px;
  }

  .p30 {
    margin-top: 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 15px;

    }
    color: $white;
  }

  .brand-logo {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 26px;
    }
    max-width: 80px;
    height: auto;
  }

  .m08-left {
    @media (max-width: $grid-float-breakpoint-max) {
      background-color: $blue;
      padding: 64px 15px 83px 15px;

    }
  }
  .m08-right {
    text-align: center;
    @media (max-width: $grid-float-breakpoint-max) {
      background-color: $light-blue;
      padding: 83px 0px 102px 0px;
    }
  }

}
