.m06 {
  margin-bottom: 80px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 30px;

  }
  .m06-content {

    h3 {

      color: $dark-blue;

    }

    text-align: center;
    width: 100%;
    border: 1px solid $grey;
    padding: 74px 15px 136px 15px;
    margin: 15px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 31px 15px 45px 15px;
    }
    .m6-ico-content {
      @media (min-width: $grid-float-breakpoint) {
        height: 210px;
      }
      .m6-ico {
        margin-top: 92px;
        margin-bottom: 50px;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-bottom: 34px;
          margin-top: 48px;
        }
      }
    }
    .description {
      max-width: 240px;
      margin: 0 auto;
    }

    a {
      color: $dark;
    }
  }
}
