// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from sass/_libraries/bootstrap/_variables.scss, paste it here and edit the value.


$primary: #557B23; // Thats a sample how you could change a BootStrap variable.

$page-break: 992px;
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $page-break;

//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($page-break - 1);



$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;

$tablet-brakpoint: 1199px;
$mobile-breakpoint: 991px;

$sidebar-base-width: 243px;

$theme-colors: (
        "primary": #182A73,
        "danger": #ff4136
);

$blue1: #182A73;
$blue2: #243787;
$main-background: #f6f7f2;
$footer-background1: #404434;
$footer-background2: #383d29;

//** Container width
$container-max-widths: (
        md: 991px,
        lg: 1199px,
        xl: 1420px
);


//nice select
// Default variables

$font_size: 16;
$font_size_small: 14px;

$input_border_radius: 5px !default;
$input_height: 42px !default;
$input_height_small: 36px !default;
$dropdown_padding: 18px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #e8e8e8 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray !default;