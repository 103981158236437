.m12 {
  margin-top: 2rem;
  .tabs_left {
    width: 470px;
    @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
      width: 210px;
    }
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  .tabs_content {
    width: calc(100% - 470px);
    @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
      width: calc(100% - 210px);
    }
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }
  nav {
    .nav {
      display: block;
      border: none;
      position: relative;
      strong {
        font-size: 16px;
        line-height: 50px;
      }
      .nav-item {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          background: url(images/ico/menu-arrow-right-white-black.svg) no-repeat;
          height: 11px;
          width: 19px;
          right: 28px;
          top: 65px;
          @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
            right: 15px;
            top: 61px;
          }
          @media (max-width: $mobile-breakpoint) {
            background: url(images/ico/arrow-down-black.svg) no-repeat;
            height: 19px;
            width: 19px;
            right: 28px;
            top: 49px;
          }
        }
        @media (max-width: $mobile-breakpoint) {
          br {
            &:first-child {
              display: none;
            }
          }
        }
      }
      .nav-link {
        border: none;
        color: $dark;
        background: $light-grey;
        border-radius: 0;
        font-size: 14px;
        line-height: 25px;
        padding: 20px 0 27px 130px;
        margin: 0;
        border-bottom: 1px solid $grey;
        &:first-child {
          border-top: 1px solid $grey;
        }
        @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
          padding: 25px 0 25px 25px;
          br {
            display: block;
            margin: -3px 0;
            content: " ";
            line-height: 23px;
            &:first-child {
              margin: 15px 0;
            }
          }
        }
        @media (max-width: $mobile-breakpoint) {
          padding: 22px 20px;
        }
      }
      .active {
        border:none;
        color: $white;
        background: $blue;
        &:after {
          content: "";
          position: absolute;
          background: url(images/ico/menu-arrow-right-white.svg) no-repeat;
          height: 11px;
          width: 19px;
          right: 28px;
          top: 65px;
          @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
            right: 15px;
            top: 61px;
          }
          @media (max-width: $mobile-breakpoint) {
            background: url(images/ico/arrow-up-white.svg) no-repeat;
            height: 19px;
            width: 19px;
            right: 28px;
            top: 49px;
          }
        }
      }
    }
  }
  .store_info {
    height: 280px;
    background: $blue;
    color: $white;
    @media (max-width: $mobile-breakpoint) {
      padding: 22px 20px;
    }
    a {
      color: $white;
    }
    .row {
      height: 100%;
      margin: 0;
      @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
        margin-left: 50px;
      }
    }
    .inlineafter {
      position: relative;
      display: inline-block;
      margin-bottom: 35px;
      text-transform: uppercase;
      @media (max-width: $mobile-breakpoint) {
        margin-bottom: 20px;
      }
      &:after {
        content: '';

        width: 100%;
        position: absolute;
        left: 0;
        bottom: -3px;

        border-width: 0 0 2px;
        border-style: solid;
      }
    }
    .line-inline {
      p {
        display: inline-block;
        min-width: 35%;
        margin: 0;
        a {
          &.p14 {
            font-size: 14px;
            line-height: 25px;
            @media (max-width: $mobile-breakpoint) {
              font-size: 12px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .map {
    width: 100%;
    height: 608px;
    @media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {
      right: 15px;
      top: 61px;
      height: 554px;
    }
    @media (max-width: $mobile-breakpoint) {
      height: 300px;
    }
    .mapinside {
      width: 100%;
      height: 100%;
    }
  }
  #accordion {
    width: 100%;
    .card {
      border: 0;
      border-bottom: 1px solid $grey;
      border-radius: 0;
      &:first-child {
        border-top: 1px solid $grey;
      }
      &:last-child {
        border-bottom: 0;
      }
      .card-header {
        border: none;
        color: $dark;

        border-radius: 0;
        font-size: 14px;
        line-height: 25px;
        padding: 0;
        margin: 0;
        button {
          padding: 20px 25px;
          width: 100%;
          text-align: left;
          text-decoration: none;
          border-radius: 0;
          color: $white;
          background: $blue;
          font-size: 14px;
          line-height: 25px;
          &.collapsed {
            color: $dark;
            background: $light-grey;
            &:after {
              background: url(images/ico/arrow-down-black.svg) no-repeat;
            }
          }
          &:after {
            content: "";
            position: absolute;
            background: url(images/ico/arrow-up-white.svg) no-repeat;
            height: 19px;
            width: 19px;
            right: 23px;
            top: 50px;

          }
          .p16 {
            font-size: 16px;
            font-weight: $bold;
          }
          .p12 {
            padding: 0;
            margin: 0;
          }
        }
      }
      .card-body {
        padding: 0;
      }
    }
  }
}