.m01 {
  padding-top: 50px;
  padding-bottom: 70px;
  background: linear-gradient(0deg, $blue 50%, #FFF 0);
  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 40px;
  }

  .slick-slide {
    .brand {
      max-width: 70px;
      @media(max-width: 767px) {
        display: none;
      }
      position: absolute;
      right: 50px;
      top: 160px;

      @media (min-width: 992px) and (max-width: 1090px) {
        right: 20px;
        top: 100px;
      }

      @media(min-width: 768px) and (max-width:991px) {
        right: 229px;
        top: 320px;
      }
    }
    .brand-xs {
      max-width: 70px;
      left: 10px;
      top: 00px;
      position: absolute;
      @media(min-width: 768px) {
        display: none;
      }
    }

    .slide-img {
      max-width: 90%;
      height: auto;
      @media(min-width: 768px) and (max-width:991px) {
       margin: 0 auto;
        max-height: 390px;
        width: auto;
        height: auto;
      }
      @media (max-width: 767px) {
        max-height: 360px;
        width: auto;
        margin: 0 auto;
      }


    }

    .button-secondary {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 0;
        margin-bottom: 30px;

        @media (max-width: 320px) {
          margin-bottom: 0px;
        }
      }
    }
  }
  .slider-background {

    .slide-bcg {
      min-height: 550px;
      max-height: 600px;
      background-image: url(./images/slider-bcg.png);
      background-size: cover;

      @media (max-width: $grid-float-breakpoint-max) {
        padding-left: 0;
        padding-right: 0;
        min-height: 350px;
        background-size: auto;
        background-position-x: 43%;
        background-position-y: 0%;
      }
      @media(min-width: 768px) and (max-width:991px) {
        background-position-x: 30%;
      }

      .slick-slider {
        margin: 0px;
      }
    }

    .home-slider {
      &.slick-initialized {
        display: block!important;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        height: 100%;
      }
      .slick-prev,
      .slick-next {
        transform: translate(0, 0);
        position: absolute;
        top: auto;
        right: 0px;
        left: auto;
        bottom: 0px;
        display: inline-block;
        width: 100px;
        height: 100px;
        background-color: rgba(255, 255, 255, 0.2);
        text-align: center;
        line-height: 100px;
        cursor: pointer;
        &:hover {
          &:after {
            opacity: 0;
            -webkit-transform: translateX(1.73333em);
            -ms-transform: translateX(1.73333em);
            -o-transform: translateX(1.73333em);
            transform: translateX(1.73333em);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
          &:before {
            opacity: 1;
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
        }
        &:after {
          background-image: url(images/ico/menu-arrow-right-white.svg);
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
          position: absolute;
          content: ' ';


          height: 19px;
          width: 19px;
          opacity: 1;
          left: 41px;
          top: 42px;
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:before {
          background-image: url(images/ico/menu-arrow-right-white.svg);
          position: absolute;
          content: ' ';
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
          height: 19px;
          width: 19px;
          opacity: 0;
          left: 41px;
          top: 42px;
          -webkit-transform: translateX(-1.73333em);
          -ms-transform: translateX(-1.73333em);
          -o-transform: translateX(-1.73333em);
          transform: translateX(-1.73333em);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
      }

      .slick-prev {
        right: 102px;
        z-index: 10;
        &:after {
          background-image: url(images/ico/menu-arrow-left-white.svg);
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:before {
          background-image: url(images/ico/menu-arrow-left-white.svg);
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
          -webkit-transform: translateX(1.9em);
          -ms-transform: translateX(1.9em);
          -o-transform: translateX(1.9em);
          transform: translateX(1.9em);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:hover {
          &:after {
            opacity: 0;
            -webkit-transform: translateX(-1.9em);
            -ms-transform: translateX(-1.9em);
            -o-transform: translateX(-1.9em);
            transform: translateX(-1.9em);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            -webkit-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            -o-transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -o-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
          &:before {
            opacity: 1;
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
        }
      }

    }
  }
  .slider-footer {
    background-color: $blue;
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 50px;
      height: 55px;
    }

    .a-primary {
      margin-top: 20px;
      display: block;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 10px;
      }

    }

    .container {
      padding-bottom: 70px;
    }
    h1 {
      color: $white !important;
      font-weight: $light;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 37px;
        margin-bottom: 30px;
      }
      @media (max-width: 767px)
      {
        margin-top: 23px;
        margin-bottom: 10px;
      }
    }
    a {
      font-weight: $light;
    }
  }
  .slide-right-mod {
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
    .slider-title-v {
      margin-top: 120px;
      color: $blue;
      font-size: 16px;
      display: flex;
      > div {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        margin: 0 15px 0 auto;
        height: 90px;
      }
    }

    .slider-number-v {
      color: $blue;
      font-size: 30px;
      font-weight: bold;
      display: block;
      float: right;
      margin-top: 20px;
      border-top: 1px solid $blue;
      padding: 10px 20px;
    }
  }

  .home-slider-footer {
    &.slick-initialized {
      display: block!important;
    }
  }
}