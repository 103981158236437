.cart-empty {
  font-family: $roboto-condensed;
  line-height: 50px;
  font-size: 40px;
  text-align: center;
}

.woocommerce-cart-form {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  @media screen and(max-width: 767px) {
    margin-top: 0;
  }

  .quantity label.screen-reader-text {
    display: none;
  }

  .quantity br {
    display: none;
  }

  .shop_table {
    border: 0 !important;
  }

  .remove {
    color: black !important;
    background-color: $light-grey;
    border-radius: 0 !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;

    &.removeall {
      color: black !important;
      font-weight: $light;
      margin-left: auto;
    }

    &:hover {
      background-color: $black !important;
    }
  }

  .shop_table {
    border: 0;

    tr {
      th {
        background: $blue;
        color: $white;
      }
    }
  }

  .product-name a {
    font-family: $roboto-condensed;
    font-size: 20px;
    line-height: 30px;
    color: $black;
    font-weight: $bold;
  }

  .product-thumbnail {
    max-width: 70px;
  }

  .product-subtotal {
    text-align: right;
    @media screen and(min-width: 768px) {
      width: 100px;
    }
  }

  .product-remove {
    width: 90px;
    padding-right: 0 !important;

  }

  .actions {
    padding-right: 0 !important;
  }


  .button-secondary {
    line-height: 23px;
  }

  .cart_totals h2 {
    display: none;
  }

  .button-addtocard {
    line-height: 9px;

    :after {
      background: url(images/ico/menu-arrow-right-white.svg) no-repeat;
    }
  }

  button.button-secondary.small-arrow {
    margin-right: 0;
  }

  .nice-select {
    border-radius: 0;
  }

  .list {
    border-radius: 0;
  }

  @media screen and(max-width: 767px) {
    .shop_table_responsive thead {
      display: block !important;
    }
    th.product-thumbnail {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 10px !important;
      line-height: 30px !important;
      font-size: 16px !important;
      letter-spacing: 0.9px !important;
    }
    th.product-name,
    th.product-price,
    th.product-quantity,
    th.product-subtotal,
    th.product-remove {
      display: none;
    }
    .woocommerce-cart-form__cart-item.cart_item td.product-name {
      text-align: left !important;

      &:before {
        display: none;
      }
    }
    .product-thumbnail {
      display: block !important;
      margin: 0 auto !important;
      max-width: 140px;
      padding: 10px 0 !important;

      &:before {
        display: none;
      }
    }
    .shop_table td {
      border: 0 !important;
    }
    .select-qty {
      min-height: initial;
    }
    table.shop_table_responsive tr:nth-child(2n) td {
      background-color: transparent;
    }
    .woocommerce-cart-form__cart-item {
      border-bottom: 1px solid #d1cece;
      position: relative;
    }
    td.product-remove {
      width: fit-content !important;
      padding: 0 !important;
      position: absolute;
      top: 89px;
      right: 3px;
      @media screen and(max-width: 767px) {
        top: 15px;
      }
    }
    td.product-name {
      width: calc(100% - 40px);
    }
    button.button-secondary.small-arrow {
      width: initial;
      float: left;
      margin: 0;
    }
  }
  @media screen and(max-width: 767px) {
    select.qty {
      width: 40px;
      height: 20px;
      padding: 0;
      border: 0;
    }

    td.product-subtotal, td.product-quantity, td.product-price {
      height: 30px;
    }
  }


  div.coupon {
    @media (min-width: 768px) {
      float: right !important;

      input#coupon_code {
        width: 160px;
      }
    }
    @media (max-width: 767px) {
      padding-top: 1em;
      text-align: center;
      float: none !important;
      input#coupon_code {
        width: 105px!important;
      }
      button {
        float: none !important;
        line-height: 18px !important;
        margin-left: 10px !important;
        width: 158px !important;
        padding: 15px 1px !important;
        &:after {
          margin-top: 4px!important;
        }
      }
    }
  }
}

.select2-container--default .select2-selection--single {
  max-width: 100% !important;
  height: 60px !important;
  border: 1px solid #D1CECE !important;
  border-radius: 0 !important;
  padding: 0 !important;
  padding-left: 20px !important;
  color: #191919 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 59px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: $blue;
  border: 1px solid $blue !important;
  color: $white;
}

.select2-dropdown {
  border: 1px solid #D1CECE !important;
  border-top: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
}

.select2-container--open .select2-dropdown {
  top: 4px;
}

.select2-results__option {
  padding: 16px 30px;
}

span.select2-dropdown.select2-dropdown--below {
  border-radius: 0 !important;
}

.cart-collaterals {
  @media (min-width: $mobile-breakpoint+1) {
    h2 {
      display: none;
    }
  }

  .cart_totals {
    background: $light-grey;

    h2 {
      padding-left: 11px;
      padding-top: 10px;
    }
  }

  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;

  .wc-proceed-to-checkout p {
    text-align: center;
  }

  .shop_table,
  .order-total th,
  .order-total td {
    border: 0 !important;
  }

  .button-addtocard {
    line-height: 9px;
    text-align: center;

    &:after {
      background: url(images/ico/menu-arrow-right-white.svg) no-repeat !important;
    }
  }

  @media (max-width: 767px) {
    .woocommerce-shipping-totals > td {
      &:before {
        content: "Transporti:" !important;

      }
    }
  }


}

.bar-container-fluid {
  max-width: 1000px;
  margin: 35px auto 70px auto;
  @media screen and(max-width: 767px) {
    margin: 25px auto 25px auto;
  }
  $brand-primary: #182a73;
  $white: #fff;
  $grey-light: #ededed;

  %remain-steps {
    &:before {
      content: counter(stepNum);
      font-family: inherit;
      font-weight: 700;
    }

    &:after {
      background-color: $grey-light;
    }
  }

  .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;

    > li {
      counter-increment: stepNum;
      text-align: center;
      display: table-cell;
      position: relative;
      color: $brand-primary;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      &:last-child {
        a {
          cursor: default;
        }
      }

      a {
        color: inherit;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        @media screen and(max-width: 600px) {
          display: none;
        }
      }

      &:before {
        content: '\2713';
        display: block;
        margin: 0 auto 20px;
        width: 10px;
        height: 10px;
        font-size: 1px;
        line-height: 10px;
        text-align: center;
        font-weight: bold;
        background-color: $brand-primary;
        border: {
          width: 2px;
          style: solid;
          color: $brand-primary;
          radius: 50%;
        }
      }

      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: $brand-primary;
        position: absolute;
        top: 4px;
        left: 50%;
        z-index: -1;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &.is-active {
        @extend %remain-steps;

        &:before {
          background-color: $brand-primary;
          border-color: $brand-primary;
        }

        ~ li {
          color: #808080;
          @extend %remain-steps;

          &:before {
            background-color: $grey-light;
            border-color: $grey-light;
          }
        }
      }
    }
  }

  .woocommerce-checkout-review-order-table {
    strong.product-quantity {
      float: right;
      line-height: 70px;
    }
  }
}

span.select2-search.select2-search--dropdown {
  display: none !important;
}
