.woocommerce {
  margin-top: 60px;
  position: relative;
  min-height: 300px;
  .woocommerce-message {
    border-top-color: $blue;
    display: none;
  }
  @media screen and(max-width: 787px) {
    margin-top: 40px;
    margin-bottom: 40px;
    position: initial;
  }

  select.qty {
    width: 100px;
    height: 60px;
    font-size: 16px;
    color: #828282;
    padding: 20px;
  }
  select#pa_size {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 62px;
    font-size: 16px;
    color: #828282;
    padding: 20px;
  }
  .m04 {
    margin-top: 50px;
    &.cart-empty {
      border: 0;
    }
  }
  #order_review_heading {
    margin-bottom: 30px;
  }
  h1 {
    text-align: center;
  }
  .woocommerce-order {
    .woocommerce-order-details__title,
    .woocommerce-thankyou-order-received {
      font-size: 25px;
      letter-spacing: 0.9px;
      font-weight: 700;
      padding-bottom: 0;
      margin-bottom: 30px;
    }
  }
  .woocommerce-order-overview__order.order {
    width: 100%;
    background-color: #182a73;
    color: #ffffff;
    line-height: 40px;
    padding-left: 20px;
  }
  ul.woocommerce-order-overview.woocommerce-thankyou-order-details.order_details {
    background-color: #f3f3f3;
    margin: 0;
    padding: 0;
    li {
      margin: 0!important;
      padding: 20px 0 23px 29px!important;
      width: 33.3%;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.54px;
      border-right: 1px solid #fff;
      text-transform: initial;
      strong {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 1px;
        font-weight: 700;
      }
      &:first-child {
        padding: 20px 0 23px 20px !important;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .thank-you-buttons {
    margin-top: 100px;
    br {
    display: none;
    }
    a {
      &:first-child {
        margin-left: 0;
      }
    }
    @media screen and(min-width: 992px) {
      &.desk-only {
        a.button-secondary.small-arrow.download-button {
          margin-bottom: 15px;
        }
      }
      &.tab-mobile {
        display: none;
      }
    }
    @media screen and(max-width: 991px) {
      &.desk-only {
        display: none;
      }
      &.tab-mobile {
        margin-top: 0;
        a {
          text-align: center;
        }
        a.button-primary.small-arrow {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }
  .foot_thankyoupage {
    background-color: #f3f3f3;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #191919;
    font-family: $roboto-condensed;
    th {
      border: 0!important;
      font-weight: 500!important;
      border-bottom: 1px solid #ffffff!important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      margin-top: 0!important;
      margin-bottom: 0!important;
      line-height: 60px!important;
    }
    td {
      border: 0!important;
      text-align: right;
      font-weight: 500!important;
      border-bottom: 1px solid #ffffff!important;
      padding-top: 0!important;
      padding-bottom: 0!important;
      margin-top: 0!important;
      margin-bottom: 0!important;
      line-height: 60px!important;
    }
    .foot-thank-row3 {
      display: none;
    }
    .foot-thank-row4 {
      th {
        font-weight: 700!important;
        color: #182a73;
        border: 0!important;
      }
      td {
        font-weight: 700!important;
        color: #182a73;
        border: 0!important;
      }
    }
    tr.order-total th {
      font-weight: 700!important;
      color: #182a73;
    }
    tr.order-total {
      color: #182a73;
    }
  }
  table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details {
    border: 0;
    border-spacing: 0;
    thead th {
      padding: 0 12px;
      line-height: 40px;
      background-color: #182a73;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
    }
    th.woocommerce-table__product-table.product-total {
      text-align: right;
    }
    td {
      text-align: right;
      padding-right: 20px;
    }
    td.woocommerce-table__product-name.product-name {
      text-align: left!important;
    }
  }
  table.shop_table.woocommerce-checkout-review-order-table {
    border: 0;
    border-spacing: 0;
    thead th {
      padding: 0 12px;
      line-height: 40px;
      background-color: #182a73;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
    }
    td {
      text-align: right;
      padding-right: 20px;
      &.product-name {
        text-align: left!important;
        font-weight: 700;
        color: #191919;
        letter-spacing: 1px;
        a {
          font-weight: 700;
          color: #191919;
          letter-spacing: 1px;
        }
      }
    }
  }
  .order_review_heading_payment_method {
    margin-bottom: 30px;
    display: none;
    &:first-of-type {
      display: block;
    }
  }
  .button-fluid {
    width: 100%;
    float: initial!important;
    margin: 0;
  }
  .download-button:after {
    transform: rotate(90deg);
  }
  .img-thumbnail-product {
    width: 110px;
    display: inline-block;
    padding: 15px;
    br {
      display: none;
    }
    @media screen and(max-width: 767px) {
        width: 100%!important;
        max-width: 120px!important;
        padding: 0 10px!important;
        line-height: 30px!important;
        font-size: 16px!important;
        letter-spacing: 0.9px!important;
      }
  }
    @media screen and(max-width: 767px) {
      .shop_table td {
        vertical-align: bottom!important;
      }
    }
  .Test2 {
    br {
      display: none;
    }
    a {
      color: #191919;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
  th.product-total {
    text-align: right;
    padding-right: 20px!important;
  }
  small.shipped_via {
    display: none;
  }
}
.woocommerce-pagination {
  ul {
    text-align: center;
    li {
          display: inline-block;
      span {
        display: block;
          width: 40px;
          height: 40px;
          border: solid 2px #182a73;
          line-height: 38px;
      }
      a, .dots {
        display: block;
        width: 40px;
        height: 40px;
        border: solid 1px #d1cece;
        line-height: 38px;
        font-family: $roboto-condensed;
        font-size: 16px;
        font-weight: $regular;
        letter-spacing: 0.5px;
        color: #828282;
        &.next,
        &.prev {
          border: 0;
        color: #191919;
        }
      }
    }
  }
}
.xoo-cp-modal {
  a.xoo-cp-close {
    background: transparent;
    border: 0;
    width: 50%;
    &.xcp-btn {
      font-size: 16px;
      font-weight: $bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $blue;
      transition: height .3s cubic-bezier(.785,.135,.15,.86),padding-left .3s cubic-bezier(.785,.135,.15,.86),padding-right .3s cubic-bezier(.785,.135,.15,.86),color .3s cubic-bezier(.785,.135,.15,.86);
      @media (max-width: $tablet-brakpoint) {
        font-size: 14px;
      }
      &:before {
        position: absolute;
        content: ' ';
        background-image: url('images/ico/arrow-left-blue.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position-y: center;
        height: 19px;
        width: 19px;
        margin-left: -2em;
        opacity: 0;
        -webkit-transform: translateX(-1.73333em);
        transform: translateX(-1.73333em);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      }
      &:after {
        position: absolute;
        content: ' ';
        background-image: url('images/ico/arrow-left-blue.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position-y: center;
        /*background-size: 19px 19px;*/
        height: 19px;
        width: 19px;
        margin-left: .4em;
        margin-bottom: -4px;
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      }

      &:hover {
        color: $blue;
        transition: padding-left .3s cubic-bezier(.785,.135,.15,.86),padding-right .3s cubic-bezier(.785,.135,.15,.86),color .3s cubic-bezier(.785,.135,.15,.86);
        padding-left: 2em;
        &:before {
          opacity: 1;
          -webkit-transform: translateX(0);
          transform: translateX(0);
          transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
        }
        &:after {
          opacity: 0;
          -webkit-transform: translateX(1.73333em);
          transform: translateX(1.73333em);
          transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
          transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
          transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
        }
      }
      &.invert {
        color: $white;
        &:before {
          background-image: url('images/ico/menu-arrow-right-white.svg');
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
        }
        &:after {
          background-image: url('images/ico/menu-arrow-right-white.svg');
          background-size: auto;
          background-repeat: no-repeat;
          background-position-y: center;
        }
      }
    }
  }
  .xoo-cp-btn-vc {
    margin: 0 15px;
    position: relative;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    height: 60px;
    background-color: $blue;
    font-family: $roboto-condensed;
    border-radius: 0;
    border: none;
    padding: 18px 0px;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 $light-blue;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
      font-size: 14px;
      padding: 15px 36px;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
      height: 50px;
      padding: 15px 36px;
    }
    &:hover {
      box-shadow: inset 400px 0 0 0 $light-blue;
    }
    &.invert {
      color: $blue;
      background-color: $white;
      font-weight: $bold;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 0 $light-grey;
      &:hover {
        box-shadow: inset 400px 0 0 0 $light-grey;
      }
      &.small-arrow {
        &:after {
          content: "";
          position: absolute;
          background: url(images/ico/arrow-right-small.svg) no-repeat;
          background-size: auto;
          background-position-y: center;
          height: 8px;
          width: 5px;
          margin-left: 10px;
          margin-top: 6px;
        }
      }
    }
    &.small-arrow {
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small-white.svg) no-repeat;
        background-size: auto;
        background-position-y: center;
        height: 8px;
        width: 5px;
        margin-left: 10px;
        margin-top: 6px;
      }
    }
  }
}

.wc-titulli{
  margin-top: 60px;
  margin-bottom: 70px;
  text-align: center;
  @media (max-width: $mobile-breakpoint) {
    margin-bottom: 35px;
    margin-top: 25px;
  }

  h1 {
    display: inline-block;
  }
}


/*temporary*/
form.woocommerce-ordering {
  select { text-align-last: right; }
  option { direction: rtl; }
  @media (max-width: $mobile-breakpoint) {
    z-index: 99999;
    top: 190px;
    right: 12px;
    display: none;
  }
}