.m04 {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  padding: 30px 0 70px;
  @media (max-width: $grid-float-breakpoint-max) {
    height: auto;
    padding: 20px 0;
  }
  h1 {
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 30px;
    }
  }
  .prod-view-more {
    text-align: right;
    a {
      font-size: 16px;
      color: $blue;
      font-weight: 600;
      letter-spacing: 0.5px;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 14px;
        padding-top: 20px;
        display: block;
      }
      @media (min-width:768px) and (max-width:991px) {
        padding-top: 0px;
      }
    }
  }

  .div-image {
    text-align: center;
  }
}