.m03 {
  padding: 117px 0 120px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 30px 0;
  }

  h1 {
    font-family: $epilogue;
    font-size: 90px !important;
    line-height: initial !important;
    padding-bottom: 33px !important;
    padding-left: 40px !important;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 30px;
      font-size: 32px !important;
    }
  }

  h2 {
    font-family: $epilogue;
    margin-bottom: 70px;
    font-weight: 700;
  }

  .box-content {
    position: relative;
    margin-bottom: 30px;

    .img-box {
      @media (max-width: $grid-float-breakpoint-max) {
        width: 100%;
      }
    }

    .box-title-content {
      position: absolute;
      bottom: 0;
      max-width: 509px;

      @media (max-width: $mobile-breakpoint) {
        width: calc(100% - 111px);
      }

      .box-title {
        letter-spacing: 0;
        display: inline-block;
        padding: 40px;
        line-height: 40px;
        margin: 0;
        color: $white;
        font-size: 30px;
        max-width: 550px;
        word-break: break-word;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 20px;
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    .box-cta {
      position: absolute;
      bottom: 19px;
      right: 34px;
      width: 110px;
      height: 110px;
      @media (max-width: $grid-float-breakpoint-max) {
        bottom: 30px;
        right: 43px;
        width: 53px;
        height: 53px;
      }
    }
  }
}