.m10 {
  .fillimi {
    text-align: center;
    margin: 25px 0;
  }
  h3 {
    margin-bottom: 20px;
    @media (max-width: $mobile-breakpoint) {
      margin-bottom: 10px;
      margin-top: 15px;

    }
  }
  nav {
  .nav {
    display: block;
    border: none;
    .nav-item {
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small.svg) no-repeat;
        height: 8px;
        width: 5px;
        right: 32px;
        margin-top: 8px;
      }
    }
    .nav-link {
      border: none;
      color: $dark;
      background: $light-grey;
      margin-bottom: 1px;
      border-radius: 0;
      font-size: 14px;
      line-height: 25px;
      padding: 18px 20px;
    }
    .active {
      border:none;
      color: $white;
      background: $blue;
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small-white.svg) no-repeat;
        height: 8px;
        width: 5px;
        right: 32px;
        margin-top: 8px;
      }
    }
    .nav-link:hover,
    .nav-link:focus {
      border: none;
    }
  }
}
  .accordion {
    .card {
      border: none;
      &:first-child {
        border-top: 1px solid $grey;
      }
      border-bottom: 1px solid $grey!important;
      .card-header {
        background: white;
        border: none;
        padding: 0;
        .btn {
          margin: 0;
          color: $dark;
          font-size: 18px;
          line-height: 30px;
          width: 100%;
          text-align: left;
          padding: 12px 0;
          &.btn-link:focus {
            text-decoration: none;
          }
          &.btn-link:hover {
            text-decoration: none;
          }
          &[aria-expanded='false']:after {
            content: "";
            position: absolute;
            background: url(images/ico/ico-plus.svg) no-repeat;
            height: 16px;
            width: 16px;
            right: 16px;
            margin-top: 8px;
          }
          &[aria-expanded='true']:after {
              content: "";
              position: absolute;
              background: url(images/ico/ico-minus.svg) no-repeat!important;
              height: 16px;
              width: 16px;
              right: 16px;
              margin-top: 15px;
            }
        }
      }
    }
  }

  ul {
    border: none;
    li {
      width: 100%;
      .nav-link:hover,
      .nav-link:focus {
        border: none;
      }
      a {
        &.dropdown-toggle {
        margin-bottom: 1px!important;
        border: none!important;
        border-radius: 0!important;
        font-size: 14px!important;
        line-height: 25px!important;
        color: $white!important;
        background: $blue!important;
        padding: 13px 20px!important;
          &:after {
            position: absolute;
            right: 20px;
            top: 23px;
          }
        }
      }
      .dropdown-menu {
        width: 100%;
        border: 1px solid $blue;
        border-radius: 0;
        padding: 0;
        a {
          margin-bottom: 1px!important;
          border: none!important;
          border-radius: 0!important;
          font-size: 14px!important;
          line-height: 25px!important;
          color: #182A73!important;
          background: #ffffff!important;
          padding: 13px 20px!important;
        }
      }
    }
  }
  .card-body {
    padding-left: 0;
    padding-top: 0;
  }
  margin-bottom: 5rem;
}