.m11 {
  margin: 7rem 0;
  @media (max-width: $mobile-breakpoint) {
    margin: 2rem 0;
  }
  .title {
    font-weight: $bold;
  }
  .description {
    p {
      font-size: 16px;
      line-height: 30px;
    }
    li {
      line-height: 30px;
    }
  }
  .accordion {
    .card {
      margin-top: 10px;
      border: none;
      border-bottom: 1px solid $grey!important;
      .card-header {
        background: white;
        border: none;
        padding: 0;
        .btn {
          position: relative;
          margin: 0;
          color: $dark;
          font-size: 20px;
          font-weight: $bold;
          line-height: 30px;
          width: 100%;
          text-align: left;
          padding: 12px 0;
          padding-bottom: 40px;
          z-index: 2;
          white-space: normal;
          &.btn-link:focus {
            text-decoration: none;
          }
          &.btn-link:hover {
            text-decoration: none;
          }
          &[aria-expanded='false']:after {
            content: "";
            position: absolute;
            background: url(images/ico/ico-plus.svg) no-repeat;
            height: 16px;
            width: 16px;
            right: 16px;
            margin-bottom: 15px;
            bottom: 0;
          }
          &[aria-expanded='true']:after {
            content: "";
            position: absolute;
            background: url(images/ico/ico-minus.svg) no-repeat!important;
            height: 16px;
            width: 16px;
            right: 16px;
            margin-bottom: 8px;
            bottom: 0;
          }
        }
        .p14 {
          margin-top: -30px;
          margin-left: 3px;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
  .card-body {
    padding-left: 0;
    padding-top: 0;
  }
}