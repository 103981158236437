.m14 {
  padding-top: 50px;
  padding-bottom: 70px;
  //background: $blue;
  background: url(./images/background-color.svg) no-repeat center center;
  background-size: cover;
  @media (min-width: $grid-float-breakpoint-max+1) {
    height: 750px;
    .h-530 {
      height: 530px;
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 40px;
    background: url(./images/background-color-mobil.svg) no-repeat center center;
    background-size: cover;
    .img-height-mobile {
      height: 210px;

      .img-fluid {
        max-height: 210px;
        margin-left: auto;
        margin-right: auto;
        padding: 14px 40px 0;
      }
    }
  }

  .same-h-div {
    @media (min-width: $grid-float-breakpoint-max+1) {
      display: flex;
      align-items: center;
      height: 530px;
      position: relative;
    }
  }

  h1 {
    width: 100%;
    @media (max-width: $grid-float-breakpoint-max) {
      height: 70px;
      padding: 0 44px 0 15px;
    }

    span {
      display: block;
      color: white;
      font-size: 100px;
      line-height: 88px;
      letter-spacing: 5px;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 26px;
        line-height: 27px;
        letter-spacing: 1.5px;
        display: initial;

        &:first-child {
          display: block;
        }
        a {
          display: none;
        }
      }
    }
  }

  a.button-secondary.invert.small-arrow {
    margin-left: 0;
    margin-top: 45px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 35px;
    }
  }

  .slick-slide {
    .brand-logo {
      position: absolute;
      right: 0;
      top: 0;
      @media (max-width: $grid-float-breakpoint-max) {
        right: 23px;
        top: -78px;
      }
    }
    .brand {
      max-width: 70px;
      @media (max-width: $grid-float-breakpoint-max) {
        max-width: 35px;
      }
    }

    .slide-img {
      margin-left: auto;
      margin-right: auto;
      display: initial;
      vertical-align: bottom;
      max-height: 100%;
      width: auto;
      @media(min-width: 768px) and (max-width: 991px) {
        margin: 0 auto;
        max-height: 390px;
        width: auto;
        height: auto;
      }
      @media (max-width: 767px) {
        max-height: 360px;
        width: auto;
        margin: 0 auto;
      }
    }

    .button-secondary {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 0;
        margin-bottom: 30px;
      }
    }
  }

  .home-slider-new {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0;
      }
    }
  }

  .slider-footer {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 50px;
      height: 55px;
    }

    .a-primary {
      margin-top: 20px;
      display: block;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 10px;
      }

    }

    .container {
      padding-bottom: 70px;
    }

    h1 {
      color: $white !important;
      font-weight: $light;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 37px;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-top: 23px;
        margin-bottom: 10px;
      }
    }

    a {
      font-weight: $light;
    }
  }

  .slide-right-mod {
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }

    .slider-title-v {
      margin-top: 120px;
      color: $blue;
      font-size: 16px;
      display: flex;

      > div {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        margin: 0 15px 0 auto;
        height: 90px;
      }
    }

    .slider-number-v {
      color: $blue;
      font-size: 30px;
      font-weight: bold;
      display: block;
      float: right;
      margin-top: 20px;
      border-top: 1px solid $blue;
      padding: 10px 20px;
    }
  }

  .home-slider-footer {
    &.slick-initialized {
      display: block !important;
    }
  }

  .slider-paging-number {
    position: absolute;
    right: 0;
    top: 40%;
    list-style-type: none;
    text-align: right;

    button {
      background: none;
      border: none;
      color: white;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 25px;
      cursor: pointer;
      padding-right: 30px;
      border-right: 5px solid transparent;

      &:focus {
        outline: none;
      }
    }

    .slick-active {
      button {
        border-right: 5px solid white;

      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 !important;
      position: initial;
      list-style-type: disc;
      text-align: center;
      li {
        display: inline-block;

        button {
          color: red;
          border: 1px solid white !important;
          margin: 0 14px;
          border-radius: 50%;
          padding: 0;
          font-size: 0;
          width: 10px;
          height: 10px;
          background-color: white;
        }
      }
      .slick-active {
        button {
          border: 1px solid white !important;
          background-color: $gray;
        }
      }
    }
  }

  .shop-for-before {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
    }
  }

  .shop-for {
    width: 230px;
    display: inline-block;
    @media (min-width: 321px) and (max-width: $grid-float-breakpoint-max) {
      width: fit-content;
      padding-right: 50px;
    }
    @media (max-width: 320px) {
      padding-right: 33px;
      width: fit-content;
    }
  }

  .view-product-mobile {
    text-align: center;

    a {
      width: 90%;
      margin-right: 0;
      max-width: 400px;
    }
  }
}