.m07 {
  padding: 30px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 30px 0;
  }

  .box-m7 {
    text-align: center;
    min-height: 455px;
    @media (max-width: $grid-float-breakpoint-max) {
      min-height: auto;
      margin-bottom: 20px;
    }

    &.video {
      @media (min-width: $grid-float-breakpoint) {
        background: $black;
        width: 100%;
        height: 455px;
        overflow: hidden;
      }

      video {
        width: 100%;
        @media (min-width: $grid-float-breakpoint) {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }

      }
    }
  }

  .sub-header {
    font-size: 12px;
     border-bottom: 2px solid $dark;
    display: inline-block;
    padding-right: 2px;
    padding-bottom: 7px;
  }

  h1 {
    margin-top: 48px;
    margin-bottom: 32px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  p {
    &.p20 {
      max-width: 578px;
    }
  }
}
