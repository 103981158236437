.elements-container {
  padding: 50px 10px;
  min-height: 160px;
  &.blue {
    background: $blue;
  }
}
a {
  &.a-primary {
    font-size: 16px;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $blue;
    transition: height .3s cubic-bezier(.785,.135,.15,.86),padding-left .3s cubic-bezier(.785,.135,.15,.86),padding-right .3s cubic-bezier(.785,.135,.15,.86),color .3s cubic-bezier(.785,.135,.15,.86);
    @media (max-width: $tablet-brakpoint) {
      font-size: 14px;
    }
    &:before {
      position: absolute;
      content: ' ';
      background-image: url('images/ico/arrow-left-blue.svg');
      background-size: auto;
      background-repeat: no-repeat;
      background-position-y: center;
      height: 19px;
      width: 19px;
      margin-left: -2em;
      opacity: 0;
      -webkit-transform: translateX(-1.73333em);
      transform: translateX(-1.73333em);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
    }
    &:after {
      position: absolute;
      content: ' ';
      background-image: url('images/ico/arrow-left-blue.svg');
      background-size: auto;
      background-repeat: no-repeat;
      background-position-y: center;
      /*background-size: 19px 19px;*/
      height: 19px;
      width: 19px;
      margin-left: .4em;
      margin-bottom: -4px;
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
      transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
    }

    &:hover {
      color: $blue;
      transition: padding-left .3s cubic-bezier(.785,.135,.15,.86),padding-right .3s cubic-bezier(.785,.135,.15,.86),color .3s cubic-bezier(.785,.135,.15,.86);
      padding-left: 2em;
      &:before {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      }
      &:after {
        opacity: 0;
        -webkit-transform: translateX(1.73333em);
        transform: translateX(1.73333em);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86);
        transition: opacity .3s cubic-bezier(.785,.135,.15,.86),transform .3s cubic-bezier(.785,.135,.15,.86),fill .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform .3s cubic-bezier(.785,.135,.15,.86);
      }
    }
    &.invert {
      color: $white;
      &:before {
        background-image: url('images/ico/menu-arrow-right-white.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position-y: center;
      }
      &:after {
        background-image: url('images/ico/menu-arrow-right-white.svg');
        background-size: auto;
        background-repeat: no-repeat;
        background-position-y: center;
      }
    }
  }
}
a {
  &.a-inline {
    font-size: 16px;
    color: $dark;
    position: relative;
    &:hover {
      color: $blue;
      &:after {
        border-color: $blue;
      }
    }
    @media (max-width: $mobile-breakpoint){
      font-size: 14px;
    }
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -10px;
      border-width: 0 0 2px;
      border-style: solid;
      border-color: $dark;
    }
  }
}
button,
a {
  @media (max-width: 320px){
    width: 100%;
  }

  &.button-primary {
    @media (max-width: 350px){
      width: 90%;
      margin-top: 15px;
    }
    margin: 0 15px;
    position: relative;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    height: 60px;
    background-color: $blue;
    font-family: $roboto-condensed;
    border-radius: 0;
    border: none;
    padding: 18px 36px;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 $light-blue;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
      font-size: 14px;
      padding: 15px 36px;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
      height: 50px;
      padding: 15px 36px;
    }
    &:hover {
        box-shadow: inset 400px 0 0 0 $light-blue;
    }
    &.invert {
      color: $blue;
      background-color: $white;
      font-weight: $bold;
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 0 $light-grey;
      &:hover {
        box-shadow: inset 400px 0 0 0 $light-grey;
      }
      &.small-arrow {
          &:after {
            content: "";
            position: absolute;
            background: url(images/ico/arrow-right-small.svg) no-repeat;
            background-size: auto;
            background-position-y: center;
            height: 8px;
            width: 5px;
            margin-left: 10px;
            margin-top: 6px;
          }
      }
    }
    &.small-arrow {
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small-white.svg) no-repeat;
        background-size: auto;
        background-position-y: center;
        height: 8px;
        width: 5px;
        margin-left: 10px;
        margin-top: 6px;
      }
    }
  }
  &.button-secondary {
    @media (max-width: 350px){
      width: 90%;
      margin-top: 15px;
    }
    margin: 0 15px;
    position: relative;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $blue;
    height: 60px;
    background-color: $white;
    font-family: $roboto-condensed;
    border-radius: 0;
    border: 2px solid $blue;
    padding: 18px 36px;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 $blue;
    transition: ease-out 0.4s;
    @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
      font-size: 14px;
      padding: 15px 36px;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
      height: 50px;
      padding: 15px 36px;
    }
    &.small-arrow {
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/arrow-right-small.svg) no-repeat;
        background-size: auto;
        background-position-y: center;
        transition: ease-out 0.4s;
        height: 8px;
        width: 5px;
        margin-left: 10px;
        margin-top: 6px;
      }
    }
    &:hover {
      &:after {
        background: url(images/ico/arrow-right-small-white.svg) no-repeat;
        background-size: auto;
        background-position-y: center;
        transition: ease-out 0.4s;
      }
    }
    &:hover {
      box-shadow: inset 400px 0 0 0 $blue;
      color: $white;
    }
    &.invert {
      color: $white;
      background-color: $blue;
      font-weight: $bold;
      border: 2px solid $white;
      box-shadow: inset 0 0 0 0 $white;
      &:hover {
        box-shadow: inset 400px 0 0 0 $white;
        color: $blue;
      }
      &.small-arrow {
        &:after {
          content: "";
          position: absolute;
          background: url(images/ico/arrow-right-small-white.svg) no-repeat;
          background-size: auto;
          background-position-y: center;
          transition: ease-out 0.4s;
          height: 8px;
          width: 5px;
          margin-left: 10px;
          margin-top: 6px;
        }
        &:hover {
          &:after {
            background: url(images/ico/arrow-right-small.svg) no-repeat;
            background-size: auto;
            background-position-y: center;
            transition: ease-out 0.4s;
          }
        }
      }
    }
  }
  &.button-addtocard {
    width: 100%;
    max-width: 340px;
    position: relative;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $white;
    height: 60px;
    background-color: $blue;
    font-family: $roboto-condensed;
    border-radius: 0;
    border: none;
    padding: 18px 36px 18px 0px;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 $light-blue;
    transition: ease-out 0.4s;
    @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
      font-size: 14px;
      padding: 15px 36px;
    }
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
      height: 50px;
      padding: 15px 36px;
    }
    &:hover {
      box-shadow: inset 400px 0 0 0 $light-blue;
    }
    &.invert {
      color: $blue;
      background-color: $white;
      font-weight: $bold;
      box-shadow: inset 0 0 0 0 $light-grey;
      &:hover {
        box-shadow: inset 400px 0 0 0 $light-grey;
      }
    }
    @media (min-width: $mobile-breakpoint + 1) {
      &:after {
        content: "";
        position: absolute;
        background: url(images/ico/cart-white.svg) no-repeat;
        height: 25px;
        width: 25px;
        margin-left: 17px;
      }
    }
  }
}

/* ---------- input ----------*/

.form-group {
  label {
    font-size: 12px;
    line-height: 20px;
    color: $dark;
  }
  small {
    display: none;
  }
}


input[type=text],
input[type=tel],
input[type=email] {
  max-width: 400px;
  border: 1px solid $grey!important;
  border-radius: 0!important;
  padding: 0!important;
  padding-left: 20px!important;
  color: $dark!important;
  &[type=text]{
    height: 60px;
    @media (max-width: $mobile-breakpoint) {
      height: 50px;
    }
  }
  &:focus {
    box-shadow: none!important;
    border: 2px solid $dark!important;
  }
  &::-webkit-input-placeholder {
    color: $dark-grey;
    font-size: 16px;
    line-height: 30px;
  }
  &::-moz-placeholder {
    color: $dark-grey;
    font-size: 16px;
    line-height: 30px;
  }
  &::-ms-input-placeholder {
    color: $dark-grey;
    font-size: 16px;
    line-height: 30px;
  }
  &::placeholder {
    color: $dark-grey;
    font-size: 16px;
    line-height: 30px;
  }
  @media (max-width: $mobile-breakpoint) {
    &::-webkit-input-placeholder {
      font-size: 14px;
      line-height: 30px;
    }
    &::-moz-placeholder {
      font-size: 14px;
      line-height: 30px;
    }
    &::-ms-input-placeholder {
      font-size: 14px;
      line-height: 30px;
    }
    &::placeholder {
      font-size: 14px;
      line-height: 30px;
    }
  }
}


.error {
  input {
    border: 2px solid $red!important;
  }
  small {
    display: block;
    color: $red!important;
  }
}



.form-check.check-box {
  input[type=checkbox] {
    display: none;
  }
  input[type="checkbox"]:checked + label:after {
    position: absolute;
    content: '';
    background-image:url('images/ico/checked.svg');
    background-size: cover;
    width: 16px;
    height: 12px;
    top: 7px;
    left: 8px;
  }
  label {
    margin-left: 20px;
    font-size: 16px;
    color: $dark;
    cursor: pointer;
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
    }
    &:before {
      content: "";
      position: absolute;
      height: 30px;
      width: 30px;
      left: 0;
      top: 45%;
      z-index: 10;
      -webkit-transform: translateY(-45%);
      transform: translateY(-45%);
      background-color: transparent;
      border: 2px solid $dark;
    }
  }
}
.form-check {
  &.radio-button {
    margin: 20px 0;
    input[type=radio] {
      display: none;
    }
    input[type="radio"]:checked + label:after {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      content: '';
      position: absolute;
      background-color: $dark;
      top: 3px;
      left: 3px;
    }
    label {
      cursor: pointer;
      line-height: 30px;
      margin-left: 20px;
      &:before {
        content: "";
        position: absolute;
        height: 30px;
        width: 30px;
        left: -2px;
        top: 45%;
        -webkit-transform: translateY(-53%);
        transform: translateY(-53%);
        border: 2px solid $dark;
        border-radius: 50%;
      }
    }
  }
}




/* ---------- end input ----------*/