.m17 {
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, .05);
  padding: 0 0 70px;
  position: relative;
  @media (max-width: $grid-float-breakpoint-max) {
    height: auto;
    padding: 0 0 60px 0;
  }

  .container {
    padding-top: 65px;
    padding-bottom: 60px;
    @media (max-width: $mobile-breakpoint) {
      padding-top: 60px;
      padding-bottom: 36px;
      padding-left: 75px;
    }

    & > div {
      display: inline-block;
    }

    .brand-logo {
      img {
        margin-right: 28px;

        &.performance {
          width: 72px;
          margin-top: -12px;
        }

        &.original {
          width: 64px;
          margin-top: -22px;
        }

        &.reebok {
          width: 74px;
          margin-top: -10px;
        }
      }
      @media (max-width: $mobile-breakpoint) {
        position: absolute;
        left: 20px;
        top: auto;
        bottom: auto;
        img {
          margin-right: 0;

          &.performance {
            width: 40px;
            margin-top: 0;
          }

          &.original {
            width: 38px;
            margin-top: -9px;
          }

          &.reebok {
            width: 45px;
            margin-top: 0;
          }
        }

      }
    }
  }

  h2 {
    font-family: $epilogue;
    font-weight: 700;
    margin-bottom: 0;
    @media (max-width: $mobile-breakpoint) {
      font-size: 21px;
      font-weight: 600;
    }
  }

  .products-row {

    @media (min-width: $mobile-breakpoint+1) {
      height: 390px;
      overflow: hidden;
    }
    @media (max-width: $mobile-breakpoint) {
      overflow: hidden;
    }
  }

  .product-box {
    border: none !important;
    border-radius: 15px !important;
    min-height: 360px !important;
    @media (max-width: $mobile-breakpoint) {
      min-height: initial !important;
      height: initial !important;
    }

    .trademark {
      height: initial;
      font-family: $epilogue;

      & > div {
        position: relative !important;
        width: 56px !important;
        height: 28px !important;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        z-index: 99999999;

        p {
          font-weight: 400 !important;
          color: #fff !important;
          padding: 0 !important;
          margin: 0 !important;
          line-height: 30px !important;
        }
      }
    }
  }

  .prod-view-more {

    @media (min-width: $mobile-breakpoint+1) {
      display: inline-block;
      float: right;
    }
    @media (max-width: $mobile-breakpoint) {
      position: absolute;
      bottom: 18px;
      right: 16px;
      z-index: 999;
    }

    img {
      margin-left: 4px;
    }

    a {
      font-family: $epilogue;
      font-size: 16px;
      color: $blue-new;
      font-weight: 600;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 14px;
        padding-top: 20px;
        display: block;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding-top: 0px;
      }
    }
  }

  .div-image {
    text-align: center;
    @media (max-width: $mobile-breakpoint) {
      position: relative;
      top: -28px;
    }
  }

  .products-row {
    @media (max-width: $mobile-breakpoint) {
      padding: 0 15px;

    }
  }

  .product-box-col {
    //width: 360px;
    display: inline-block;
    //margin: 0 15px;
  }

  .product-box-link {
    .div-image {
      .product-image {
        @media (min-width: $mobile-breakpoint+1) {
          max-width: 240px;
          margin: 20px auto 19px auto !important;
          min-width: 240px;
          min-height: 240px;
        }
        @media (max-width: $mobile-breakpoint) {
          position: relative;
          top: 0;
          margin: 0 auto!important;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
      }
    }

    .product_title_price {
      @media (min-width: $mobile-breakpoint+1) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px 0 0 !important;
        margin: 0 30px;
      }
      @media (max-width: $mobile-breakpoint) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px 30px 0 !important;
        margin: 0;
        position: relative;
        top: -28px;
        margin-bottom: 0!important;
      }

      .prod_price del {
        top: -20px !important;
      }

      .prod_title {
        font-family: $epilogue;
        font-size: 16px !important;
        font-weight: 700 !important;
        letter-spacing: -0.3px !important;
        @media (max-width: $mobile-breakpoint) {
          font-size: 14px!important;
          height: initial!important;
          margin: 0!important;
        }
      }
      .prod_price {
        @media (max-width: $mobile-breakpoint) {
          font-size: 14px!important;
        }
      }
    }
  }


  .slick-slide {
    //width: 360px !important;
    margin: 0 15px;
    padding: 0;
    @media (max-width: $mobile-breakpoint) {
      margin: 0 10px;
      padding: 0;
    }
  }

  .arrows {
    .prev {
      position: absolute;
      top: 290px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      top: 290px;
      right: 0;
      cursor: pointer;
    }
    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }


}