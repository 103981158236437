#overlay-gallery {
  margin: 0 auto;
  display: none;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1); /* Black background with opacity */
  z-index: 10000!important; /* Specify a stack order in case you're using a different order for other elements */
  color: #FFF;

  .gallery-close {
    display: block;
    text-align: right;
    img {
      width: 18px;
    }
  }
  .single-product-gallery {
    max-width: 1200px;
    margin: 0 auto;
    padding: 90px 0;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 30px 0;
    }

    .page-slider-gallery {
      @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
      }
      .slick-slide {

        .slide-img {
          width: 100%;
          height: auto;
        }
      }

      .slick-dots {
        margin-top: 30px;
        position: relative;
        bottom: auto;

        @media (max-width: $grid-float-breakpoint-max) {
          margin-top: 15px;
        }

        li {
          display: inline-block;
          margin: 0px;
          padding: 15px 28px 18px 20px;
          border-bottom: 2px solid $light-grey;
          height: 55px;

          @media (max-width: 767px) {
            padding: 15px 25px 18px 15px;
          }

          a {
            color: #828282;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.54px;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }

          &.slick-active {
            border-bottom: 2px solid $white;

            a {
              color: $white;
              font-weight: 600;
            }
          }
        }
      }

      .slick-prev, .slick-next {
        top: auto;
        bottom: 8px;
        z-index: 10;
      }

      .slick-prev {
        left: 0px;
        @media (max-width: 767px) {
          left: 15px;
        }
        &:before {
          content: "";
          display: inline-block;
          background-image: url(./images/ico/slider-arrow-left-white.svg);
          background-repeat: no-repeat;
          width: 28px;
          height: 16px;
        }
      }
      .slick-next {
        right: 7px;
        @media (max-width: 767px) {
          right: 22px;
        }
        &:before {
          content: "";
          display: inline-block;
          background-image: url(./images/ico/slider-arrow-right-white.svg);
          background-repeat: no-repeat;
          width: 28px;
          height: 16px;
        }
      }
    }
  }
}