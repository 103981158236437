/**Mega Menu **/
.navbar .container {
  position: relative;
}

#navbar {
  padding: 0;
  background-color: $white;
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: right;
  }
  @media (max-width: $mobile-breakpoint) {
    height: 60px;
  }


  .navbar-toggler {
    padding-top: 17px;
    color: $white;
    right: 0;
    top: 0;
  }

  #primary-menu {

    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
      text-align: left;
      padding-left: 20px;
      padding-top: 30px;
      padding-right: 20px;
    }
    @media (min-width: $grid-float-breakpoint) {
      padding-top: 10px;
    }

    li {
      a {
        position: relative;
        @media (min-width: $grid-float-breakpoint) {
          font-size: 18px;
          margin-right: 60px;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        border-bottom: 1px solid $white;
      }

      &:last-child {
        @media (max-width: $grid-float-breakpoint-max) {
          border: none;
        }
      }

      a {
        color: rgba(0, 0, 0, 0.9);
        font-family: $epilogue;
        font-size: 16px;
        font-weight: 600;


        @media (max-width: $grid-float-breakpoint-max) {
          color: $white;
        }

        @media (min-width: $grid-float-breakpoint) {
          line-height: 1.3 !important;
          &:hover {
            &:before {
              visibility: visible;
              transform: scaleX(1);
              // width: 100%;
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -10px;
            left: 0;
            background-color: $light-blue;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
          }
        }
      }
    }

    @media (min-width: $grid-float-breakpoint) and (max-width: 1480px) {

      //margin-left: 20px !important;
      li {

        a {
          //font-size: 18px;
          //margin-right: 20px;
        }
      }

    }
    @media (min-width: $grid-float-breakpoint) and (max-width: 1270px) {

      li {

        a {
          font-size: 15px;
        }
      }

    }
  }
}

.sub-menu {
  @media (max-width: 1199px) {
    display: none !important;
  }
  background: $blue;
  padding-top: 180px;
  z-index: 400;
  position: relative;

  h5, h4 {
    margin: 0;
  }

  .sub-menu-box {
    cursor: pointer;
    border: 1px solid #fff;
    margin: 0 auto 60px;

    &.adidas-logo {
      background-image: url(images/svg/Adidas_Logo-white.svg);
      background-repeat: no-repeat;
      background-size: 133px;
      background-position: center;
    }

    &.reebok-logo {
      background-image: url(images/svg/Reebok_2019_logo_white.svg);
      background-repeat: no-repeat;
      background-size: 155px;
      background-position: center;
    }


    &:hover,
    &.active {
      background-color: $white;
      color: $black;

      h4 {
        color: $black !important;
      }


      &.adidas-logo {
        background-image: url(images/svg/Adidas_Logo-black.svg);
      }

      &.reebok-logo {
        background-image: url(images/svg/Reebok_2019_logo_black.svg);
      }

      &.femije {
        background: none;
      }
    }

    .sub-menu-box-title {
      position: relative;
      height: 180px;
      text-align: center;

      h4 {
        color: $white;
        line-height: 180px;
        font-size: 34px;
        text-transform: uppercase;
        @media (min-width: 776px) and (max-width: 1310px) {
          font-size: 25px;
        }

        &:hover {
          color: $black;
        }
      }

      img {
        width: 123px;
        height: 100%;
      }
    }

    .sub-menu-indicator {
      display: inline-block;
      height: 180px;
      width: 100px;
      line-height: 180px;
      background: $blue;
      color: $white;
      text-align: center;
    }

    &.two-column {
      .sub-menu-box-title {
        height: 280px;
        line-height: 280px;

        h4 {
          line-height: 280px;
        }
      }

      .sub-menu-indicator {
        height: 280px;
        line-height: 280px;
      }
    }

    &.has-children {

      .childrens {
        display: none;
      }

      &.femije {

        &:hover,
        &.active {
          .childrens {
            display: flex;
            height: 180px
          }

          div.child {
            background-color: $blue;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-right: 1px solid $white;

            h5 {

              font-size: 18px;
              line-height: 30px;
              text-align: center;
              color: $white;
              text-decoration: none;
            }

            &:hover,
            &.active {
              background-color: $white;

              h5 {
                color: $blue1
              }

            }

          }

          .col-4 {
            &:last-child {
              .child {
                border-right: none;
              }
            }
          }

          .sub-menu-box-title {
            display: none;
          }

          .sub-menu-indicator {
            display: none;
          }
        }
      }


      &.two-column {
        &:hover {
          .childrens {
            display: flex;
            height: 280px
          }

          a {
            display: block;
            line-height: 280px;

            h5 {
              line-height: 280px;
            }
          }
        }
      }
    }
  }

  .sub-menu-close {
    border-top: 1px solid #fff;
    background: $blue;
    text-align: center;

    > div {
      height: 60px;
      color: $white;
      line-height: 60px;
    }

    .close-submenu {
      span {
        cursor: pointer;
      }
    }
  }


  .sub-category {
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 15px;


    h5 {
      border-bottom: 2px solid $blue1;
      width: fit-content;
      padding-right: 17px;
      font-weight: bold;
      color: $blue1;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0;

      li {
        margin: 5px 0;

        a {
          color: $blue1;
          font-size: 18px;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }
  }

  .right-ico {
    width: 10px;
    height: 10px;
    margin-top: -4px;
    margin-left: 5px;
  }
}

#navbar-mob {
  z-index: 100;
  width: 100%;
  position: fixed;
  text-align: right;
  background-color: $blue1;
  height: 60px;
  @media (min-width: $grid-float-breakpoint) {
    display: none !important;
  }
}

body #sm_menu_ham {

  @media (max-width: $mobile-breakpoint) {
    &.open {
      display: none !important;
    }
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto !important;

    visibility: hidden;
    cursor: pointer !important;
    height: 36px !important;
    position: fixed !important;
    width: 36px !important;
    top: 28px !important;
    line-height: 0 !important;
    z-index: 9999 !important;
    color: #000 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    &:after {
      visibility: visible;
      position: fixed;
      content: '';
      background-image: url('images/ico/hamburger-menu.svg');
      -webkit-background-size: cover;
      background-size: cover;
      height: 36px;
      width: 39px;

    }
  }
  @media (max-width: 991px) {


    visibility: hidden;
    cursor: pointer !important;
    height: 36px !important;
    position: fixed !important;
    width: 36px !important;
    top: 10px !important;
    line-height: 0 !important;
    z-index: 9999 !important;
    right: 14px !important;
    color: #000 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    &:after {
      visibility: visible;
      position: fixed;
      content: '';
      background-image: url('images/ico/hamburger-menu-mobile.svg');
      -webkit-background-size: cover;
      background-size: cover;
      width: 31px;
      height: 36px;
    }
  }
  @media (max-width: 330px) {
    right: 7px !important;
  }
}
@media (max-width: 330px) {
  right: 7px !important;
}
.sm_menu_outer {
  @media (max-width: 1199px) and (min-width: 992px) {
    top: 65px!important;
  }
}