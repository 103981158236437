.woof {
  z-index: 399;
  position: relative;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  background-color: transparent;
  @media (max-width: $mobile-breakpoint) {
    padding: 0!important;
  }
  .chosen-container.chosen-container-single a.chosen-single,
  .chosen-container-multi .chosen-choices {
    height: 60px!important;
    width: 100%!important;
    line-height: 60px!important;
    padding-left: 20px;
    background: transparent;
    border-radius: 0;
    border: 2px solid #191919;
  }
  .chosen-container-multi .chosen-results li {
    width: 52px;
    height: 40px;
    display: inline-block!important;
    margin: 10px!important;
  }
  .woof_container {
    width: 25%;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      max-width: 348px;
      margin: 0 auto;
    }
  }
  .woof_block_html_items p {
    display: none;
  }
  .chosen-container.chosen-container-single {
    width: 100%!important;
  }
  .chosen-search {
    display: none;
  }
  .chosen-container-single .chosen-single div {
    top: 14px;
  }
  .chosen-drop {
    border: 2px solid black!important;
    border-top: 0!important;
  }
  .chosen-with-drop a {
    border-bottom: 0!important;
  }
  li.active-result,
  li.disabled-result {
    height: 40px;
    line-height: 28px;
    font-family: $roboto-condensed;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    letter-spacing: 0.5px;
    text-align: center;
    color: #828282;
    background-image: none;
    background-color: transparent;
    border: solid 1px #d1cece;
    margin: 10px 0;
    overflow: hidden;
  }
  li.result-selected {
    height: 40px;
    line-height: 28px;
    font-family: $roboto-condensed;
    font-size: 16px;
    font-style: normal;
    font-stretch: condensed;
    letter-spacing: 0.5px;
    text-align: center;
    background-image: none;
    background-color: transparent;
    margin: 10px 0;
    border: solid 2px #191919;
    color: #182a73!important;
    font-weight: $bold;
    overflow: hidden;
  }
  li.highlighted {
    background-color: transparent!important;
    background-image: none!important;
    color: initial!important;
  }
  .chosen-container .chosen-results {
    margin: 0;
    padding: 20px;
  }
  .chosen-container .chosen-results {
    max-height: 100%;
  }
  span.irs-grid {
    display: none!important;
  }
  span.irs-line {
    height: 3px;
    top: 30px;
  }
  span.irs-bar {
    height: 3px;
    top: 30px;
  }
  span.irs-slider {
    width: 20px;
    height: 20px;
    border: solid 2px #1b2758;
    border-radius: 50%;
    background: #fff;
  }
  .irs {
    height: 60px;

  }
  .irs-from,
  .irs-to {
    background: #182a73!important;
    &:after {
      border-top-color: #182a73!important;
    }
  }
  .woof_redraw_zone {
    background: #fff;
  }
  label.woof_checkbox_label {
    width: 52px;
    height: 40px;
    border: solid 1px #d1cece;
    padding: 0;
    margin: 0;
    line-height: 36px;
    text-align: center;
    font-family: $roboto-condensed;
    font-size: 16px;
    font-weight: $regular;
    letter-spacing: 0.5px;
    color: #828282;
  }
  label.woof_checkbox_label_selected {
    border: solid 2px #182a73;
  }
  .icheckbox_flat-aero {
    display: none;
  }
  .woof_submit_search_form_container {
    display: none;
  }
  .woof_sid_auto_shortcode .woof_container_inner {
    padding-right: 5px;
  }
  .woof_checkbox_sales_container.woof_container.woof_container_onsales {
    display: none;
  }
  .chosen-container {
    max-width: 100%;
  }
  @media (min-width: $mobile-breakpoint+1) {
    .woof_container {
      float: left;
      margin-right: 12px;
    }
  }
  @media (max-width: $mobile-breakpoint) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .chosen-container-multi .chosen-choices li.search-field input[type=text] {
    padding-left: 0!important;
  }
}
.woof_products_top_panel {
  a[data-tax='onsales'] {
    display: none;
  }
  li a {
    border-radius: 2px;
    border: solid 1px #090f27 !important;

    letter-spacing: 0.5px;
    color: #191919;
    padding: 6px 9px;
    font-weight: $regular;
    span {
      font-family: $roboto-condensed;
      font-size: 14px;
      background: none;
      position: relative;
      &:after {
        position: absolute;
        content: ' ';
        background-image: url(/wp-content/themes/dardaniasport/images/ico/x.svg);
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        top: 3px;
        right: 0;
      }
    }
  }
}
.woof_products_top_panel {
  width: 100%;
}
.woof_price3_search_container.woof_container.woof_price_filter {
  padding-bottom: 0;
  margin-bottom: 0!important;
}