.m18 {
  padding-top: 30px;
  padding-bottom: 70px;
  max-width: 100%;
  overflow: hidden;
  @media (max-width: $tablet-brakpoint) {
    padding-bottom: 35px;
  }
  @media (max-width: $mobile-breakpoint) {
    padding-bottom: 21px;
  }

  h1 {
    width: 100%;
    font-family: $epilogue;
    font-size: 90px;

    @media (min-width: $mobile-breakpoint+1) {
      margin-top: 170px;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      height: initial;
      padding: 0 20px 0;
      line-height: 0;
      max-width: 446px;
      text-align: center;
      margin: 0 auto;
    }
    @media (max-width: 767px) {
      height: initial;
      padding: 0 20px 0;
      line-height: 0px;
      max-width: 302px;
    }

    span {
      font-family: $epilogue;
      font-size: 90px;
      display: block;
      color: $black;
      line-height: 88px;
      letter-spacing: 5px;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 26px;
        letter-spacing: 1px;
        display: initial;
        color: white;
        line-height: initial;

        &:first-child {
          display: block;
        }
        a {
          display: none;
        }
      }

      &.line-1 {
        @media (min-width: 768px) and (max-width: 1199px) {
          font-size: 70px;
          height: initial;
        }
        @media (max-width: 767px) {
          height: 54px;
        }
      }

      &.line-2 {
        font-size: 28px;
        font-weight: 500;
        line-height: initial;
        letter-spacing: initial;
        opacity: 0.5;
        display: inline-block;
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 0;
        }
      }

      &.line-3 {
        font-size: 32px;
        font-weight: 500;
        line-height: initial;
        letter-spacing: initial;
        display: inline-block;
        @media (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 0;
        }
      }
    }
  }

  a.button-secondary.invert.small-arrow {
    margin-left: 0;
    margin-top: 45px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 35px;
    }
  }

  .slick-slide {
    .brand-logo {
      position: absolute;
      right: 0;
      top: 0;
      @media (max-width: $grid-float-breakpoint-max) {
        right: 23px;
        top: -78px;
      }
    }

    .brand {
      max-width: 70px;
      @media (max-width: $grid-float-breakpoint-max) {
        max-width: 35px;
      }
    }

    .slide-img {
      margin-left: auto;
      margin-right: auto;
      display: initial;
      vertical-align: bottom;
      max-height: 100%;
      width: auto;
      @media(min-width: 768px) and (max-width: 991px) {
        margin: 0 auto;
        max-height: 390px;
        width: auto;
        height: auto;
      }
      @media (max-width: 767px) {
        max-height: 360px;
        width: auto;
        margin: 0 auto;
      }
    }
  }

  .slider-footer {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 50px;
      height: 55px;
    }

    .a-primary {
      margin-top: 20px;
      display: block;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 10px;
      }

    }

    .container {
      padding-bottom: 70px;
    }

    h1 {
      color: $white !important;
      font-weight: $light;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 37px;
        margin-bottom: 30px;
      }
      @media (max-width: 767px) {
        margin-top: 23px;
        margin-bottom: 10px;
      }
    }

    a {
      font-weight: $light;
    }
  }

  .slide-right-mod {
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }

    .slider-title-v {
      margin-top: 120px;
      color: $blue;
      font-size: 16px;
      display: flex;

      > div {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        margin: 0 15px 0 auto;
        height: 90px;
      }
    }

    .slider-number-v {
      color: $blue;
      font-size: 30px;
      font-weight: bold;
      display: block;
      float: right;
      margin-top: 20px;
      border-top: 1px solid $blue;
      padding: 10px 20px;
    }
  }

  .home-slider-footer {
    &.slick-initialized {
      display: block !important;
    }
  }

  .slider-paging-number {
    position: absolute;
    right: 0;
    top: 40%;
    list-style-type: none;
    text-align: right;

    button {
      background: none;
      border: none;
      color: $black;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 25px;
      cursor: pointer;
      padding-right: 30px;
      border-right: 5px solid transparent;

      &:focus {
        outline: none;
      }
    }

    .slick-active {
      button {
        border-right: 5px solid black;

      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 !important;
      position: initial;
      list-style-type: disc;
      text-align: center;
      li {
        display: inline-block;

        button {
          color: red;
          border: 1px solid white !important;
          margin: 0 14px;
          border-radius: 50%;
          padding: 0;
          font-size: 0;
          width: 10px;
          height: 10px;
          background-color: white;
        }
      }
      .slick-active {
        button {
          border: 1px solid white !important;
          background-color: $gray;
        }
      }
    }
  }

  .shop-for-before {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
    }
  }

  .shop-for {
    width: 230px;
    display: inline-block;
    @media (min-width: 321px) and (max-width: $grid-float-breakpoint-max) {
      width: fit-content;
      padding-right: 50px;
    }
    @media (max-width: 320px) {
      padding-right: 33px;
      width: fit-content;
    }
  }

  .view-product-mobile {
    text-align: center;

    a {
      width: 90%;
      margin-right: 0;
      max-width: 400px;
    }
  }

  a.button-secondary.invert.small-arrow {
    color: black;
    border: 2px solid #000000;
  }


  .image-box {
    background-color: $blue-new;
    border-radius: 27px;
    height: 500px;
    width: 337px;
    max-width: 100%;
    @media (max-width: $mobile-breakpoint) and (min-width: $tablet-brakpoint) {
      height: 337px;
      width: 302px;
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      height: 600px;
      width: 100%
    }
    @media (max-width: 767px) {
      height: 370px;
      width: 100%;
    }
    @media (max-width: 767px) {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }

    .image-box-inside {
      background: url(./images/ico/home-banner-background.svg) no-repeat center center;
      background-size: cover;
      height: 100%;
      border-radius: 27px;
      box-shadow: 0 0 35px 3px rgba(7, 18, 154, 0.65);
      @media (max-width: 767px) {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
      }
    }

    img {
      position: absolute;
      top: 90px;
      margin: 0 auto;
      right: 0;
      left: 0;
      @media (min-width: 768px) and (max-width: 1199px) {
        top: initial;
        bottom: 100px;
        left: 0;
        right: 0;
        margin: 0;
        max-width: initial;
        max-height: initial;
        width: 100%;
      }
      @media (max-width: 767px) {
        top: initial;
        bottom: 0;
        left: 20px;
        right: initial;
        margin: 0;
        max-width: 85%;
        max-height: 225px;
      }
    }

    .button-new {
      position: absolute;
      bottom: 10%;
      margin: 0 auto;
      right: 0;
      left: 0;
      @media (min-width: 768px) and (max-width: 1199px) {
        bottom: 4%;
      }
      @media (max-width: 767px) {
        display: none;
      }

      &:hover {
        color: black;
      }
    }
  }

  .paging-number {
    display: flex;
    margin: 0;
    padding: 0 0 32px 0;

    @media (min-width: $tablet-brakpoint+1) {
      margin: 0 auto;
      width: fit-content;
    }
    @media (max-width: $tablet-brakpoint) {
      padding: 0;
      position: initial;
      bottom: initial;
      margin: 0 auto;
      width: fit-content;
      text-align: center;
      margin-top: 38px;
    }


    span {
      cursor: pointer;
      margin: 0 0.25rem;
      @media (max-width: $tablet-brakpoint) {
        margin: 0 21px;
      }
      @media (max-width: $mobile-breakpoint) {
        margin: 0 5px;
      }
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 0;
      border: 1px solid $blue-new;
      border-radius: 100%;
      text-indent: -9999px;
      @media (max-width: $tablet-brakpoint) {
        width: 15px;
        height: 15px;
      }
      @media (max-width: $mobile-breakpoint) {
        width: 12px;
        height: 12px;
      }
    }

    .swiper-pagination-bullet-active {
      //margin: 0 62px 0 0;
      //@media (max-width: $tablet-brakpoint) {
      //  margin: 0 42px 0 0;
      //}
      //@media (max-width: $mobile-breakpoint) {
      //  margin: 0 37px 0 0;
      //}


      background-color: $blue-new;
      width: 82px;
      border-radius: 17px;
      transition: width 0.4s ease-in-out;
      @media (max-width: $tablet-brakpoint) {
        width: 59px;
      }
      @media (max-width: $mobile-breakpoint) {
        width: 51px;
      }

    }
  }

  .order-lg-2.order-1 {
    @media (max-width: $mobile-breakpoint) {
      position: absolute;
      top: 40px;
    }
  }

  .swiper-slide {
    transition: all 1s ease;

    &.swiper-slide-prev {
      .image-box {

        .image-box-inside {

          box-shadow: none!important;
        }
      }

      @media (min-width: $mobile-breakpoint+1) {
        transform: scale(0.8, 0.8);
        right: -65%;
        opacity: 0.5;
        bottom: -60px;
        .col-xl-5.offset-xl-0.col-lg-12.order-lg-2.order-1 {
          display: none;
        }
        .image-box {
          width: 100%;

          img {
            position: absolute;
            top: 170px;
            margin: 0 auto;
            right: 0;
            left: 0;
          }
        }
        .button-new {
          display: none;
        }
      }
      @media (min-width: 768px) {
        transform: scale(0.8, 0.8);
      }

      @media (min-width: 768px) and (max-width: 1199px) {
        opacity: 0.5;
        right: -50px;
        img {
          bottom: 165px;
          width: 85%;
          left: 0;
          right: 0;
        }
        .col-xl-5.offset-xl-0.col-lg-12.order-lg-2.order-1 {
          display: none;
        }
        .button-new {
          display: none;
        }
      }
      @media (max-width: 767px) {
        right: 20px;
      }
      @media (max-width: 767px) {
        right: 20px;
      }
    }

    &.swiper-slide-next {
      transition: none;
      left: -60vH;
      opacity: 0.5;

      .image-box {
        border-radius: 27px!important;
        .image-box-inside {
          border-radius: 27px!important;
          box-shadow: none!important;
        }
      }

      @media (min-width: $mobile-breakpoint+1) {
        transform: scale(0.8, 0.8);
        bottom: -60px;
        .button-new {
          display: none;
        }
        .image-box {
          img {
            position: absolute;
            top: 170px;
            margin: 0 auto;
            right: 0;
            left: 0;
          }
        }
      }
      @media (min-width: 768px) {
        transform: scale(0.8, 0.8);
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        left: -50px;
        img {
          bottom: 165px;
          width: 100%;
          left: 0;
          right: 0;
        }
        .col-xl-5.offset-xl-0.col-lg-12.order-lg-2.order-1 {
          display: none;
        }
        .button-new {
          display: none;
        }
      }
      @media (max-width: 767px) {
        left: 20px;
        .image-box {
          border-radius: 27px;
        }
      }
    }


    &.swiper-slide-active {
      z-index: 99999;
    }
  }

}