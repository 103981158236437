.m09 {
  padding: 30px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 30px 0;
  }

  .sub-header {
    font-size: 12px;
    border-bottom: 2px solid $dark;
    display: inline-block;
    padding-right: 2px;
    padding-bottom: 7px;
  }

  h1 {
    margin-top: 48px;
    margin-bottom: 32px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  p {
    &.p20 {
      max-width: 578px;
    }
  }

  .p-content {
    ul {
      padding: 0px;
      li {
        list-style: none;
        font-size: 20px;
        letter-spacing: 0.68px;
        line-height: 40px;
        display: flex;
        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:before {
          content:"·";
          font-size: 30px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }

  .box-m9 {
    &.blue {
      text-align: center;
      background: $blue;
      padding: 105px 120px 145px 120px;

      @media (max-width: 767px) {
        padding: 65px 30px 90px 30px;

      }
      .p25 {
        color: $white;
        max-width: 451px;
        margin: 0 auto;
        letter-spacing: 0.85px;
        line-height: 50px;
      }
    }
  }
}
