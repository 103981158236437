@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700);
@import url(https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap);

$roboto-condensed: 'Roboto Condensed', sans-serif;
$epilogue: 'Epilogue', sans-serif;;

$light: 300;
$regular: 400;
$bold: 700;

/* color variables */
$white: #ffffff;

$blue: #182A73;
$blue-new: #00119A;
$blue-light-new: #00C2FF;
$super-light-blue: #8FEBF8;
$light-blue: #243797;
$dark-blue: #090F27;
$dark: #191919;

$grey: #D1CECE;
$light-grey: #F3F3F3;
$dark-grey: #828282;

$red: #B62828;
/* end color variables */
/*------------------*/
/* color classes */
.blue {
  color: $blue;
}
.super-light-blue {
  color: $super-light-blue;
}
.light-blue {
  color: $light-blue;
}
.dark-blue {
  color: $dark-blue;
}
.dark {
  color: $dark;
}
.grey {
  color: $grey;
}
.light-grey {
  color: $light-grey;
}
.dark-grey {
  color: $dark-grey
}
.red {
  color: $red;
}

/* end color classes */
/*------------------*/
body {
  font-family: $roboto-condensed;
  font-size: 15px;
  color: $dark;
  font-weight: $regular;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: $bold;
  letter-spacing: 1.25px;
  @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
    font-size: 35px;
    line-height: 45px;
  }
  @media (max-width: $mobile-breakpoint) {
    font-size: 30px;
    line-height: 35px;
  }
}
/*
h2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: $bold;
  @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
    font-size: 25px;
    line-height: 35px;
  }
  @media (max-width: $mobile-breakpoint) {
    font-size: 20px;
    line-height: 25px;
  }
}
 */
h2 {
  font-weight: 700;
}
h3 {
  font-size: 25px;
  line-height: 35px;
  font-weight: $bold;
  @media (min-width: $mobile-breakpoint + 1) and (max-width: $tablet-brakpoint) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
    line-height: 30px;
  }
}
h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: $bold;
  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
    line-height: 30px;
  }
}
h5 {
  font-size: 18px;
  line-height: 30px;
  @media (max-width: $mobile-breakpoint) {
    font-size: 16px;
    line-height: 25px;
  }
}
h6 {
  font-size: 16px;
  line-height: 25px;
  @media (max-width: $tablet-brakpoint) {
    font-size: 14px;
    line-height: 25px;
  }
}


p {

  @media (max-width: $mobile-breakpoint) {
    font-size: 20px;
    line-height: 25px;
  }
  &.p30 {
    font-size: 30px;
    line-height: 40px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 25px;
      line-height: 40px;
    }
  }
  &.p25 {
    font-size: 25px;
    line-height: 50px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 20px;
      line-height: 35px;
    }
  }
  &.p20 {
    font-size: 20px;
    line-height: 40px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 18px;
      line-height: 35px;
    }
  }
  &.p16 {
    font-size: 16px;
    line-height: 30px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 25px;
    }
  }
  &.p14 {
    font-size: 14px;
    line-height: 25px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 12px;
      line-height: 25px;
    }
  }
  &.p12 {
    font-size: 12px;
    line-height: 20px;
  }
}
a {
  font-size: 15px;
  line-height: 22px;
  font-weight: $regular;
  letter-spacing: -0.3px;
  color: $green;
  &:hover {
    text-decoration: none;
    color: $green;
  }
  @media (max-width: $mobile-breakpoint) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.25px;
  }
}

.button-new {
  border-radius: 33px;
  border: none;
  font-family: $epilogue;
  text-transform: initial;
  font-weight: 400!important;
  padding: 22px 0;
  width: 255px;
  position: relative;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #182A73;
  height: 60px;
  background-color: #ffffff;
  display: inline-block;
  cursor: pointer;
  -webkit-box-shadow: inset 0 0 0 0 #182a73;
  box-shadow: inset 0 0 0 0 #182a73;
  -webkit-transition: ease-out 0.4s;
  -o-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}