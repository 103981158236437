footer {
  font-family: $epilogue;
  margin-top: 130px;

  @media (max-width: $mobile-breakpoint) {
    margin-top: 100px;
  }

  .social {

    @media (min-width: $mobile-breakpoint+1) {
      float: right;
    }
    @media (max-width: $mobile-breakpoint) {
      display: block;
      margin-top: 30px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
    }

    p {
      color: #c4c4c4;
      line-height: 150%;
      margin: 0 0 13px 0;
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }

    }

    .social-title {
      margin-bottom: 15px;
    }

    .social-icons {
      margin-right: 20px;
      .social-icon-mobile {
        display: inline-block;
        &:hover {
          opacity: 0.2;
        }
      }
      @media (max-width: $mobile-breakpoint) {
        margin: 0 10px;
        img {
          filter: invert(1);
        }
        .social-icon-mobile {

          width: 52px;
          height: 52px;
          background-color: $blue-new;
          border-radius: 100%;
          padding: 13px;
        }
      }
    }
  }

  .cards {
    @media (min-width: $mobile-breakpoint+1) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    @media (max-width: $mobile-breakpoint) {
      margin-top: 35px;
    }


    .cards-title {
      margin-top: 42px;
      margin-bottom: 15px;
    }

    .card-icons {
      max-width: 50px;
    }

    .cards-logos {
      width: 262px;
      background-color: #ffffff;
      text-align: center;
      @media (max-width: $mobile-breakpoint) {
        margin: 0 auto;
      }

      .card-icons {
        max-width: 41px;
        margin: 7px 4px;
      }
    }
  }


  .footer-content {
    position: relative;

    & > div {

      @media (min-width: $mobile-breakpoint+1) {
        display: inline-block;
        vertical-align: top;
      }
      @media (max-width: $mobile-breakpoint) {
        display: block;
      }
    }

    .sherbimet-tona {

      @media (min-width: $mobile-breakpoint+1) {
        border-right: 2px solid rgba(0, 0, 0, .1);
      }
      @media (max-width: $mobile-breakpoint) {
        display: block;
      }

      .sherbimet-tona-conent {
        max-width: 350px;

        @media (max-width: $mobile-breakpoint) {
          margin: 0 auto;
        }

        h2 {
          margin-bottom: 60px;
          @media (max-width: $mobile-breakpoint) {
            font-size: 21px;
          }
        }

        .sherbimi {
          position: relative;
          margin-right: 35px;

          img {
            width: 30px;
            position: absolute;
            top: 5px;
          }

          p {
            font-weight: 600;
            display: inline-block;
            padding-left: 50px;
            opacity: 0.5;
            line-height: 1.5;
          }

          &:first-child {
            img {
              width: 36px;
            }

            p {
              padding-left: 56px;
            }
          }
        }
      }
    }

    .footer-menu {
      margin-left: 75px;
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }

      p {
        color: #c4c4c4;
        line-height: 210%;
        margin: 0;
      }

      .footer-menu-list {
        padding: 0;
        list-style: none;
        margin: 0;


        li {
          padding: 0;

          a {
            font-weight: 600;
            color: black;
            font-size: 14px;
            line-height: 210%;

            &:hover {
              color: #ddd;
            }
          }
        }
      }
    }
  }

  .footer-produktet {
    position: absolute;
    width: 400px;
    bottom: -10px;

    .footer-menu-list {
      padding: 0;
      list-style: none;
      margin: 0;


      li {
        padding: 0;
        display: inline-block;
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }

        a {
          font-weight: 600;
          color: black;
          font-size: 14px;

          &:hover {
            color: #ddd;
          }
        }
      }
    }
    @media (max-width: $tablet-brakpoint) {
      display: none;
    }
  }

  .autoresia {
    margin-top: 80px;
    background-color: $blue-new;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 15px;
    }

    p {
      font-weight: 400;
      padding: 10px 0;
      margin: 0;
      text-align: center;
      color: #ffffff;
    }
  }
}
@media (min-width: $mobile-breakpoint + 1 ) and (max-width: $tablet-brakpoint) {

}
@media (max-width: $mobile-breakpoint) {

}