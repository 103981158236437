.breadcrumbs {
  padding-top: 15px;
  ul {
    padding-left: 0px;
    list-style: none;
    li {
      display: inline-block;
      &:after {
        content: "";
        background: url(images/ico/arrow-right-small-black.svg) no-repeat;
        background-size: auto;
        background-position-y: center;
        transition: ease-out 0.4s;
        height: 8px;
        width: 5px;
        padding-left: 10px;
        margin-left: 10px;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}