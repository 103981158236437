.products {
  .product {
    border: 1px solid $grey;
    @media (min-width: $grid-float-breakpoint) {
      margin: 0 1.8% 1.992em 0 !important;
      width: 23.6% !important;

      min-height: 430px;
    }

    &.last {
      margin-right: 0px !important;
    }

    a {
      img {
        max-width: 240px;
        margin: 30px auto 30px auto !important;
      }
    }
  }
  .ajax_add_to_cart, .product_type_variable {
    display: none !important;
  }

  @media (min-width: 768px) and (max-width: $grid-float-breakpoint-max) {
    li {
      width: calc(50% - 30px) !important;
      margin: 15px !important;
      padding: 0 !important;
      border: 1px solid #D1CECE !important;
    }
  }

  @media (max-width: 767px) {
    margin: 0 -15px 0!important;
    border-top: 1px solid #D1CECE!important;
    li {
      width: 50%!important;
      margin: 0!important;
      padding: 0!important;
      border: 0!important;
      border-left: 1px solid #D1CECE!important;
      border-bottom: 1px solid #D1CECE!important;
    }
  }
}

.product-box-wc {
  .products-row {
    height: initial;
    @media (min-width: $grid-float-breakpoint) {
      max-height: 490px;
      z-index: 300;
    }
  }
  padding: 0;
  position: relative;
  z-index: 199;
  background: $white;
  margin-bottom: 30px;
  min-height: 465px;
  border: 1px solid $grey;
  @media (max-width: 767px) {
    min-height: 346px;
    margin-bottom: -1px;
    border-left: none;
  }
  h2 {
    display: none;
  }

  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  .product-box-link {
    display: block;
  }

  img {
    max-width: 100%;
  }

  .product-info {
    position: relative;
    margin-top: 50px;
    padding: 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 30px;
      padding: 20px;
    }

    h5 {
      color: $dark;
      margin-bottom: 0;
      @media (min-width: $grid-float-breakpoint) {
        padding-top: 0px;
        margin-top: -5px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        padding-top: 0;
      }
    }

    .prod-price {
      color: $dark;
      font-size: 18px;
      text-align: right;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 16px;
        text-align: left;
      }
    }

    .sale-price {
      position: absolute;
      top: 10px;
      right: 30px;
      color: $dark-grey;
      font-size: 14px;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 12px;
        right: auto;
        left: 20px;
        top: 0;
      }
    }
  }

  .product-variations {
    display: none;
    height: 70px;
    padding-left: 30px;
    margin-bottom: 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      display: none !important;
    }

    a {
      margin-right: 10px;

      img {
        border: 1px solid transparent;

        &:hover {
          border: 1px solid $blue1;
        }
      }

    }
  }

  /* product box */
  &:hover {
    @media (min-width: $grid-float-breakpoint) {
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border: 1px solid #efefef;
      .product-variations {
        display: block;
      }
    }
  }

  .price {
    float: right;
    max-width: 30%;
    font-size: 18px !important;
    font-family: $roboto-condensed;
    font-weight: 700 !important;
    letter-spacing: 0.6px;
    text-align: right;
    color: #191919;
    padding-right: 30px;
    @media (max-width: 767px) {
      float: left!important;
      text-align: left!important;
      width: 100%!important;
      max-width: 100%!important;
      position: absolute;
      bottom: 40px;
      left: 30px;
    }

    .woocommerce-Price-amount {
      &.amount {
        color: $black;
      }
    }
  }
}

.onsale {
  display: none;
}



.product-box-wc,
.product-box-col {
  &:nth-child(even) {
    .product-box {
      @media (max-width: 767px) {
        border-left: 1px solid white;
        border-right: 1px solid white;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0!important;
  }
  .product-box {
    position: relative;
    z-index: 300;
    background: $white;
    margin-bottom: 30px;
    min-height: 430px;
    border: 1px solid $grey;
    @media (max-width: 767px) {
      min-height: 346px;
      margin-bottom: -1px;
    }
    .product-box-link {
      display: block;
    }
    img {
      &.product-image {
        max-width: 240px;
        margin: 30px auto 30px auto!important;
        width: 100%;
      }
    }
    .product-variations {
      display: none;
      height: 70px;
      padding-left: 30px;
      margin-bottom: 20px;
      @media (max-width: $grid-float-breakpoint-max) {
        display: none !important;
      }
      a {
        margin-right: 10px;
        img {
          border: 1px solid transparent;
          &:hover {
            border: 1px solid $blue1;
          }
        }

      }
    }
    /* product box */
    &:hover {
      @media (min-width: $grid-float-breakpoint) {
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        border: 1px solid #efefef;
        .product-variations {
          display: block;
        }
      }
    }
  }
  .trademark {
    position: relative;
    height: 80px;
    @media (max-width: $grid-float-breakpoint-max) {
      height: 60px;
    }
    .trademark-logo {
      height: 35px;
      padding: 0;
      margin-left: 30px;
      margin-top: 20px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 20px;
      }
    }
    .sale-box {
      height: 80px;
      width: 80px;
      background: $red;
      color: $white;
      position: absolute;
      text-align: center;
      right: 0;
      top: 0;
      line-height: 80px;
      font-size: 18px;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 16px;
        height: 60px;
        width: 60px;
        line-height: 60px;
      }
    }
    .new-sign {
      height: 80px;
      width: 80px;
      background: $super-light-blue;
      color: $black;
      position: absolute;
      text-align: center;
      right: 0;
      top: 0;
      line-height: 80px;
      font-size: 18px;
      font-weight: 700;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 16px;
        height: 60px;
        width: 60px;
        line-height: 60px;
      }
    }
  }
  .product_title_price {
    padding: 0 30px;
    br {
      display: none;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 20px;
    }
    .prod_price {
      float: right;
      color: $dark;
      font-size: 18px !important;
      font-family: $roboto-condensed;
      font-weight: 700 !important;
      letter-spacing: 0.6px;
      text-align: right;
      position: relative;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 16px!important;
        text-align: left;
        float: left;
        width: 100%;
        display: block;
        margin-bottom: 15px;
      }
      del {
        font-family: $roboto-condensed;
        position: absolute;
        top: -24px;
        right: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.79;
        letter-spacing: 0.5px;
        text-align: right;
        color: #828282;
        @media (max-width: $mobile-breakpoint) {
          left: 0;
          right: initial;
        }
      }
      ins {
        text-decoration: none;
      }
    }
    .prod_title {
      position: relative;
      color: $dark;
      margin-bottom: 0;
      @media (min-width: $grid-float-breakpoint) {
        font-weight: 500 !important;
        font-size: 14px !important;
        letter-spacing: 0.5px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        padding-top: 0;
        width: 100%;
        display: block;
        margin-bottom: 15px;
        overflow: hidden;
        height: 23px;
      }
    }
  }
}
.woof_container_inner_productcategories {
  .chosen-results {
    li {
      &:first-child {
        display: none;
      }
    }
  }
}

.woof_edit_view {
  display: none;
}

.woocommerce-ordering {
  position: absolute;
  top: 5px;
  right: 0;
  select.orderby {
    font-family: $roboto-condensed;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: 1px;
    color: $dark-blue;
    border: 0;
    background-color: transparent;
  }
}

.woof {
  @media (max-width: $grid-float-breakpoint-max) {
   // display: none;
  }
}
#show-filters {
  @media (min-width: $grid-float-breakpoint) {
    display: none!important;
  }
}
.chosen-container-multi {
  @media (max-width: $grid-float-breakpoint-max) {
   width: 100%!important;

    .chosen-choices {
      .search-field {
        input {
          &.default {
            width: 100%!important;
          }
        }
      }
    }
  }
}

.btn-filters {
  position: relative;
  background-color: #FFF;
  border: 2px solid #191919;
  color: #191919;
  border-radius: 0;
  width: 100%;
  max-width: 348px;
  padding: 0;
  margin: 0 auto;
  height: 60px;
  line-height: 60px;

  &:focus {
    box-shadow: none;
  }

  &:before {

  }
  &.small-arrow {
    &:after {
      content: "";
      position: absolute;
      background: url(images/ico/arrow-right-small.svg) no-repeat;
      background-size: auto;
      background-position-y: center;
      height: 60px;
      line-height: 60px;
      width: 5px;
      right: 10px;
      margin-left: 10px;
      margin-top: 0px;
    }
  }
}

.largo {
  display: none;
}

@media (max-width: 991px) {
  .woof_container_product_cat {
    display: none;
  }
}