.single-product {
  .description_product {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #d1cece;
    margin-top: 100px;
    padding: 70px 0;
  }
  .single-brand {
    max-width: 90px;
    max-height: 35px;
  }

  .woocommerce-product-gallery {

    .woocommerce-product-gallery__trigger {
      display: none !important;
    }

    .full-size-ico {
      position: absolute;
      bottom: 20px;

      img {
        max-width: 21px;
      }
    }

    .flex-control-thumbs {
      border-left: 1px solid $grey;
      padding-left: 25px !important;
      margin-left: 60px !important;
      margin-top: 30px !important;
      max-height: 63px;

      li {
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        max-width: 60px;

        &:nth-child(4n+1) {
          clear: none !important;
        }

        img {
          border: 2px solid $grey;

          &:hover {
            border: 2px solid #1B2758;
          }

          &.flex-active {
            border: 2px solid #1B2758;
          }
        }
      }
    }
  }

  .entry-summary {
    .woocommerce-Price-amount {
      color: $blue;
      font-size: 30px;
      margin-top: 60px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 20px;
        font-size: 20px;
      }
    }
  }

  .related {
    &.products {
      display: inline-block;
      width: 100%;
    }
  }


  .product-type-simple .quantity_select {
    display: block;
    width: 100%;
  }

  h1 {
    text-align: left;
  }

  .related.products h2 {
    margin-bottom: 50px;
    padding-top: 80px;
    text-align: center;
    border-top: 1px solid #d1cece;
    @media screen and(max-width: 787px) {
      border: none;
    }
  }

  .title-sku {
    @media screen and(max-width: 787px) {
      margin-top: -15px;
    }

    p {
      margin: 0;
    }
  }

  .button-addtocard {
    min-width: 200px;
  }

  .product-details-additional {
    display: inline-block;

    border-top: 1px solid #d1cece;
    padding: 70px 0;

    &.related-prod {
      h2 {
        text-align: center;
      }

      @media screen and(max-width: 787px) {
        ul.products.columns-4 {
          margin: 0 !important;
        }
      }
    }

    @media screen and(max-width: 787px) {
      padding: 30px 0;

    }
  }
}

.product_meta {
  display: none !important;
}

.variations {
  .label {
    display: none;
  }
}

.single_variation_wrap {
  max-width: 350px;
}

.button-addtocard {
  margin-top: 20px;
}

.woocommerce div.product div.summary {
  width: 49%;
}