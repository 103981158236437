.m16 {
  background-color: $blue-new;
  height: 30px;
  margin-top: 10px;


  p {
    font-family: $epilogue;
    font-style: italic;
    color: $blue-light-new;
    padding: 0;
    margin: 0;
    line-height: 30px;
    text-align: center;
  }

  //.autoresia {
  //  p {
  //    position: absolute;
  //    width: 100%;
  //    height: 30px;
  //    margin: 0;
  //    line-height: 30px;
  //    text-align: center;
  //    /* Starting position */
  //    -moz-transform: translateX(100%);
  //    -webkit-transform: translateX(100%);
  //    transform: translateX(100%);
  //    /* Apply animation to this element */
  //    -moz-animation: scroll-left 30s linear infinite;
  //    -webkit-animation: scroll-left 30s linear infinite;
  //    animation: scroll-left 30s linear infinite;
  //  }
  //
  //  /* Move it (define the animation) */
  //  @-moz-keyframes scroll-left {
  //    0% {
  //      -moz-transform: translateX(100%);
  //    }
  //    100% {
  //      -moz-transform: translateX(-100%);
  //    }
  //  }
  //  @-webkit-keyframes scroll-left {
  //    0% {
  //      -webkit-transform: translateX(100%);
  //    }
  //    100% {
  //      -webkit-transform: translateX(-100%);
  //    }
  //  }
  //  @keyframes scroll-left {
  //    0% {
  //      -moz-transform: translateX(100%); /* Browser bug fix */
  //      -webkit-transform: translateX(100%); /* Browser bug fix */
  //      transform: translateX(100%);
  //    }
  //    100% {
  //      -moz-transform: translateX(-100%); /* Browser bug fix */
  //      -webkit-transform: translateX(-100%); /* Browser bug fix */
  //      transform: translateX(-100%);
  //    }
  //  }
  //}
}