
/*Main style*/

.mobile_menu {
  @media (max-width: 1199px) {
    display: block!important;
  }
  position: relative;
  padding-left: 0;

  li {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #eee;

    &.back {
      background-color: #efefef;
    }

  }

  .btn-primary {
    margin: 30px 20px;
    padding: 14px!important;
    left:20px;
    right: 20px;
    color: $white!important;
    border-radius: 0;

  }
}
.sm_menu_outer.active .mobile_menu {
  display: block;
}
.sm_menu_outer {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 60px;
  z-index: -9;
  width: 100%;
  opacity: 0;
  background-color: #FFF;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
  @media (max-width:1199px) and (min-width:992px) {
    height: calc(100vh - 120px);
  }
}
.sm_menu_outer.active {
  opacity: 1;
  z-index: 9999;
}
.sm_menu_outer .mobile_menu li.back a {
  padding: 20px 5px 20px 55px;
  background: url("./images/ico/menu-back.svg") no-repeat scroll 15px center / 20px auto;
}
.sm_menu_outer .mobile_menu a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  display: block;
  letter-spacing: 1px;
  padding: 20px 15px;
  text-transform: uppercase;
  text-decoration: none;
}
/*Sub Menu anim */
.sm_menu_outer .mobile_menu .submenu {
  padding-left: 0px;
  background-color: #FFF;
  height: 100%;
  position: absolute;
  right: -100%;
  top: 0;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 999;
}
.sm_menu_outer .mobile_menu li.active>.submenu {
  right: 0;
}
.sm_menu_outer .mobile_menu li {
  list-style-type: none;
  color: #ffffff;
}
.sm_menu_outer .mobile_menu li.hasChild>a {
  background: url("./images/ico/menu-arrow-right-black.svg") no-repeat scroll 100% center / 7px auto;
}
/*Hamburger Animation */
#sm_menu_ham {
  cursor: pointer;
  height: 25px;
  position: fixed;
  width: 80px;
  top: 0px;
  line-height: 60px;
  z-index: 9999;
  right: 60px;
  color: $black;
  padding-right: 80px;
  @media (max-width:1199px) and (min-width:992px) {
    line-height: 111px;
    padding-left:0px!important;
    right: 60px!important;
    margin-right: 10px;
  }
}
#sm_menu_ham span {
  background-color: #FFFFFF;
  border-radius: 5px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  -o-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  width: 30px;
}
#sm_menu_ham span:nth-child(1) {
  top: 0;
}
#sm_menu_ham span:nth-child(2),
#sm_menu_ham span:nth-child(3) {
  top: 10px;
}
#sm_menu_ham span:nth-child(4) {
  top: 20px;
}
#sm_menu_ham.open span:nth-child(1) {
  left: 50%;
  top: 18px;
  width: 0;
}
#sm_menu_ham.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
#sm_menu_ham.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#sm_menu_ham.open span:nth-child(4) {
  left: 50%;
  top: 18px;
  width: 0;
}
/*@media (min-width: 0px) and (max-width: 767px) {}
@media (min-width: 768px) and (max-width: 991px) {}
@media (min-width: 992px) and (max-width: 1024px) {}*/
@media (min-width: 1200px) {
  #sm_menu_ham,
  .sm_menu_outer {
    display: none;
  }
}

