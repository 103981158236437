.site-header {
  height: 80px;
  @media (max-width: $mobile-breakpoint) {
    height: 60px;
  }

  .site-title {
    float: left;
    @media (max-width: $grid-float-breakpoint-max) {
      padding-left: 20px;
    }

    a {
      img {
        @media (min-width: $grid-float-breakpoint) {
          margin-top: -10px;
          width: 180px;
          height: 59px;
        }
        @media (max-width: 1199px) and (min-width: 992px) {
          margin-left: 15px;
        }
        @media (max-width: $grid-float-breakpoint-max) {
          max-width: 135px;
          width: 135px;
          height: 38px;
          margin-top: -2px;
          display: inline;

          &.logo-small-white {
            display: none;
            padding-top: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .site-description {
    display: none !important;
  }

  .mod-blue {
    height: 80px;
    color: $white;
    @media (max-width: $grid-float-breakpoint-max) {
      height: auto;
    }
  }

  .mod-search {
    &.mod-blue {
      @media (max-width: $grid-float-breakpoint-max) {
        text-align: center;
        background-color: $white;
        color: $dark;
      }
    }
  }

  #header-close-menu-mob {
    // display: none;
    display: none;
    background: $light-blue;
    height: 60px;
    padding-top: 18px;
    padding-right: 25px;
    width: 60px;
  }

  #header-cart-mob {
    display: inline-block;
    @media (max-width: 330px) {
      width: initial !important;
    }

    img {
      @media (max-width: $mobile-breakpoint) {
        width: 30px;
      }
    }

  }

  .header-cart {
    display: block;
    text-align: right;
    @media (max-width: 1199px) and (min-width: 992px) {
      margin-right: 28px;
    }

    div {

      border-radius: 50%;
      position: absolute;
      top: -10px;
      right: 0;
      font-size: 14px;
      font-family: $epilogue;
      font-weight: 700;
      font-style: normal;
      color: $blue-new;
      @media (max-width: 1199px) and (min-width: 992px) {
        right: 21px;
      }
    }
  }

  .header-cart-mob {
    display: inline-block;
    margin-top: 6px;
    margin-right: 77px;
    position: relative;
    @media (max-width: 330px) {
      margin-right: 65px;
    }
    @media (max-width: 1199px) and (min-width: 992px) {

      display: none !important;

    }

    div {
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 12px;
      font-weight: 700;
      font-style: normal;
      color: #191919;
      text-align: center;
    }
  }

  #header-close-menu-mob {
    margin-right: 6px;
  }

  .search-form {
    position: absolute;
    margin-top: 21px;
    left: -200%;

    @media (max-width: 1199px) and (min-width: 992px) {
      position: absolute;
      margin-top: 21px;
      left: initial;
      right: 0;
      .search-field {
        margin-left: 0 !important;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      position: absolute;
      top: -34px;
      right: 124px;
      margin: 0;
      left: initial;
    }
    @media (max-width: 330px) {
      right: 100px;
    }

    .search-field {
      /*
      margin-left: -135px;
      width: 340px;
      height: 60px;
      box-shadow: -2px 0px 9px rgba(0, 0, 0, 0.05);
      float: left;
      */

      padding: 9px 0 6px 36px;
      border-radius: 30px;
      border: 1px solid transparent;

      width: 20px;
      box-sizing: border-box;
      font-family: $epilogue;
      font-size: 16px;
      background-color: white;
      background-image: url(./images/ico/search.svg);
      background-position: 10px 10px;
      background-repeat: no-repeat;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;


      &:focus {
        width: 300px;
        border: 1px solid #eee;
        outline: 0;
        @media (max-width: $mobile-breakpoint) {
          width: 230px
        }
        @media (max-width: 330px) {
          width: 200px
        }
      }

      @media (max-width: 1199px) {
        margin-left: auto;
        margin-right: auto;
        width: 36px;
        height: 36px;
        margin-top: 0;
        border: 0;
      }
      @media (max-width: 1199px) and (min-width: 992px) {
        margin-left: -200px;
      }

    }

    .search-submit {
      background-color: transparent;
      background-image: url(./images/ico/search-blue.svg);
      background-position: 0 16px;
      background-repeat: no-repeat;
      width: 50px;
      height: 50px;
      padding: 18px;
      border: none;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 14px;
      }

      &:focus {
        outline: 0;
      }

      &:hover {
        cursor: pointer;
      }

    }
  }
}

.mmactive {
  .search-form {
    display: none;
  }

  @media (max-width: 1199px) and (min-width: 992px) {
    a.header-cart {
      display: none;
    }
  }

}
