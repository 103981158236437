.error404 {
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 70px;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
  .error,
  .cta {
    width: 50%;
    height: 380px;
    @media (max-width: $mobile-breakpoint) {
      width: 100%;
      height: 240px;
    }
  }
  .error {
    background-color: $blue;
        p {
      color: $light-blue;
      font-size: 100px;
      line-height: 50px;
      letter-spacing: 8.26px;
      font-weight: $bold;
      @media (max-width: $mobile-breakpoint) {
        font-size: 50px;
      }
    }
  }
  .cta {
    background-color: $light-blue;
    @media (max-width: $mobile-breakpoint) {
      margin-top: -1px;
    }
  }
}