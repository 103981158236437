.woocommerce-billing-fields {
  label {
    width: 100%!important;
    font-size: 14px;
  }
  .woocommerce-input-wrapper {
    width: 100%;
  }
  input {
    max-width: 100%!important;
    height: 60px!important;
  }
  @media screen and(max-width: 991px) {
  .form-row-first,
  .form-row-last {
      width: 100%!important;
    }
  }
  span.optional {
    display: none;
  }
}
.woocommerce-shipping-fields {
  label {
    width: 100%!important;
    font-size: 14px;
  }
  .woocommerce-input-wrapper {
    width: 100%;
  }
  input {
    max-width: 100%!important;
    height: 60px!important;
  }
  input[type="checkbox"] {
    height: initial!important;
  }
  @media screen and(max-width: 991px) {
    .form-row-first,
    .form-row-last {
      width: 100%!important;
    }
  }
}
.payment_method_woo_nestpay img {
  display: none!important;
}
.woocommerce-form-coupon-toggle {
  display: none!important;
}
.woocommerce-form-coupon-toggle {
  display: none;
}